<template>
  <div class="re-order-btn">
    <md-button
      class="md-raised md-primary large-width"
      @click="reOrder()"
      :disabled="loading"
    >
      <span v-if="loading">
        Processing
        <md-progress-spinner
          :md-diameter="20"
          md-mode="indeterminate"
          :md-stroke="3"
        ></md-progress-spinner>
      </span>
      <span v-else>Reorder</span>
    </md-button>
  </div>
</template>

<script>
export default {
  name: "ReOrderBtn",
  props: ["order"],
  data: function() {
    return {
      loading: false
    };
  },
  methods: {
    reOrder: function() {
      this.loading = true;
      this.$store
        .dispatch("projects/reOrder", this.order)
        .then(() => {
          this.loading = false;
          this.$toastr("success", "Order Successfully Added to Cart!");
          this.$router.push({ name: "my-cart" });
        })
        .catch(() => {
          this.loading = false;
          this.$toastr("error", "Order Unsuccessfully Added to Cart");
        });
    }
  }
};
</script>

<style lang="scss">
.re-order-btn {
  .md-button {
    span .md-progress-spinner {
      vertical-align: middle;
      margin-left: 5px;
    }
  }
}
</style>
