<template>
  <div id="new-release" :class="{ editing: editing }">
    <section class="section head">
      <div class="container">
        <h1>ADD A RELEASE</h1>
      </div>
    </section>

    <section class="section md-layout container">
      <form
        novalidate
        @submit.prevent="validateRelease"
        class="md-layout-item md-small-size-100"
      >
        <div class="form-group release-information">
          <h4>RELEASE INFORMATION</h4>

          <md-field
            class="album md-layout md-alignment-top-left"
            :class="{ 'md-invalid': errors.album }"
          >
            <label
              class="md-layout-item md-size-15 md-small-size-100"
              for="album"
            >
              Album or Single Title
            </label>
            <md-input
              class="md-layout-item md-small-size-100"
              v-model="release.album"
            />
            <span class="md-error" v-show="errors.album">
              {{ errors.album }}
            </span>
          </md-field>

          <md-datepicker
            id="datepicker"
            :md-disabled-dates="disabledDates"
            v-model="release.release_date"
            :class="{ 'md-invalid': errors.release_date }"
            class="album md-layout md-alignment-top-left"
          >
            <label
              for="release-date"
              class="md-layout-item md-size-15 md-small-size-100"
            >
              Release Date
            </label>
            <span class="md-error" v-show="errors.release_date">
              {{ errors.release_date }}
            </span>
          </md-datepicker>

          <md-field
            class="upc md-layout md-alignment-top-left"
            :class="{ 'md-invalid': errors.record_number }"
          >
            <label
              class="md-layout-item md-size-15 md-small-size-100"
              for="record_number"
            >
              Record #
            </label>
            <md-input
              class="md-layout-item md-small-size-100"
              v-model="release.record_number"
            />
            <span class="md-error" v-show="errors.record_number">
              {{ errors.record_number }}
            </span>
          </md-field>

          <md-field
            class="upc md-layout md-alignment-top-left"
            :class="{ 'md-invalid': errors.upc }"
          >
            <label
              class="md-layout-item md-size-15 md-small-size-100"
              for="upc"
            >
              UPC
            </label>
            <md-input
              class="md-layout-item md-small-size-100"
              v-model="release.upc"
              v-mask="'############'"
            />
            <div class="upc-isrc-warn" v-show="showUpcWarn">
              UPC should be 12 digits
            </div>
          </md-field>
        </div>

        <div class="form-group distribution">
          <h4>
            WHERE WILL THIS BE DISTRIBUTED?

            <span class="md-error" v-show="errors.distribution">
              {{ errors.distribution }}
            </span>
          </h4>

          <div
            class="md-layout md-gutter"
            :class="{ 'md-invalid': errors.distribution }"
          >
            <div class="md-layout-item md-size-30 md-small-size-100">
              <md-radio v-model="release.distribution" value="United States">
                United States
              </md-radio>
            </div>

            <div class="md-layout-item md-size-30 md-small-size-100">
              <md-radio
                :disabled="editing"
                v-model="release.distribution"
                value="Other countries"
              >
                Other countries
              </md-radio>
            </div>
          </div>
        </div>

        <md-content
          class="md-accent international"
          v-if="release.distribution == 'Other countries'"
        >
          <p>
            Licenses obtained through Songfile are limited to the making and
            distribution of phonorecords of musical compositions within the
            United States only. If you are distributing your recording in other
            territories, you must contact the mechanical rights society that
            covers that territory. Licenses for territories other than the
            United States are not available through Songfile.
          </p>
        </md-content>

        <div
          class="form-group license-types"
          v-if="release.distribution != 'Other countries'"
        >
          <h4>
            TYPE OF LICENSE

            <span class="md-error" v-show="errors.selected_types">
              {{ errors.selected_types }}
            </span>
          </h4>

          <div
            class="md-layout md-gutter"
            :class="{ 'md-invalid': errors.selected_types }"
          >
            <div class="md-layout-item md-size-25 md-small-size-100">
              <md-checkbox
                :disabled="disabledTypes.includes('physical')"
                v-model="release.selected_types"
                value="physical"
              >
                Physical
              </md-checkbox>
            </div>

            <div class="md-layout-item md-size-25 md-small-size-100">
              <md-checkbox
                :disabled="disabledTypes.includes('digital')"
                v-model="release.selected_types"
                value="digital"
              >
                Download
              </md-checkbox>
            </div>

            <div class="md-layout-item md-size-25 md-small-size-100">
              <md-checkbox
                :disabled="disabledTypes.includes('ringtone')"
                v-model="release.selected_types"
                value="ringtone"
              >
                Ringtone
              </md-checkbox>
            </div>

            <div class="md-layout-item md-size-25 md-small-size-100">
              <md-checkbox
                :disabled="disabledTypes.includes('streaming')"
                v-model="release.selected_types"
                value="streaming"
              >
                Streaming
              </md-checkbox>
            </div>
          </div>
        </div>

        <div
          class="form-group n-copies"
          v-if="release.distribution != 'Other countries'"
        >
          <h4 v-show="release.selected_types.length > 0">
            How many total copies of the recording will you make?

            <div
              class="md-error"
              v-if="errors['types-empty'] && errors['types-empty'].length"
            >
              You need to type the number of copies for:&nbsp;
              {{ errors["types-empty"].join(", ") }}
            </div>
          </h4>

          <div class="md-layout md-gutter">
            <md-field
              class="md-layout-item md-size-25 md-small-size-100"
              :class="{ 'md-invalid': copiesError('physical.CD') }"
              v-if="release.selected_types.includes('physical')"
            >
              <div
                class="md-error copies-max"
                v-if="
                  errors.copies && typeof errors.copies.physical == 'string'
                "
              >
                {{ errors.copies.physical }}
              </div>

              <label class="md-layout-item md-size-50" for="cd">CD</label>

              <md-input
                class="md-layout-item md-size-50"
                v-mask="'###############'"
                v-model="copies.physical.CD"
              ></md-input>

              <div class="md-error" v-if="copiesError('physical.CD')">
                {{ errors.copies.physical.CD }}
              </div>
            </md-field>

            <md-field
              class="md-layout-item md-size-25 md-small-size-100"
              :class="{ 'md-invalid': copiesError('physical.Cassette') }"
              v-if="release.selected_types.includes('physical')"
            >
              <label class="md-layout-item md-size-50" for="cassette">
                Cassette
              </label>

              <md-input
                class="md-layout-item md-size-50"
                v-mask="'###############'"
                v-model="copies.physical.Cassette"
              ></md-input>

              <div class="md-error" v-if="copiesError('physical.Cassette')">
                {{ errors.copies.physical.Cassette }}
              </div>
            </md-field>

            <md-field
              class="md-layout-item md-size-25 md-small-size-100"
              :class="{ 'md-invalid': copiesError('physical.Vinyl') }"
              v-if="release.selected_types.includes('physical')"
            >
              <label class="md-layout-item md-size-50" for="vinyl">Vinyl</label>

              <md-input
                class="md-layout-item md-size-50"
                v-mask="'###############'"
                v-model="copies.physical.Vinyl"
              ></md-input>

              <div class="md-error" v-if="copiesError('physical.Vinyl')">
                {{ errors.copies.physical.Vinyl }}
              </div>
            </md-field>

            <md-field
              class="md-layout-item md-size-25 md-small-size-100"
              :class="{ 'md-invalid': copiesError('digital') }"
              v-if="release.selected_types.includes('digital')"
            >
              <label class="md-layout-item md-size-50" for="download">
                Permanent Digital Download
              </label>

              <md-input
                class="md-layout-item md-size-50"
                v-mask="'###############'"
                v-model="copies.digital.Download"
              ></md-input>

              <div class="md-error" v-if="copiesError('digital')">
                {{ errors.copies.digital }}
              </div>
            </md-field>

            <md-field
              class="md-layout-item md-size-25 md-small-size-100"
              :class="{ 'md-invalid': copiesError('ringtone') }"
              v-if="release.selected_types.includes('ringtone')"
            >
              <label class="md-layout-item md-size-50" for="ringtone">
                Ringtone
              </label>

              <md-input
                class="md-layout-item md-size-50"
                v-mask="'###############'"
                v-model="copies.ringtone.Ringtone"
              ></md-input>

              <div class="md-error" v-if="copiesError('ringtone')">
                {{ errors.copies.ringtone }}
              </div>
            </md-field>

            <md-field
              class="md-layout-item md-size-25 md-small-size-100"
              :class="{ 'md-invalid': copiesError('streaming') }"
              v-if="release.selected_types.includes('streaming')"
            >
              <label class="md-layout-item md-size-50" for="streaming">
                Interactive Streaming
              </label>

              <md-input
                class="md-layout-item md-size-50"
                v-mask="'###############'"
                v-model="copies.streaming.streaming"
              ></md-input>

              <div class="md-error" v-if="copiesError('streaming')">
                {{ errors.copies.streaming }}
              </div>
            </md-field>
          </div>
        </div>

        <div
          class="md-layout md-alignment-top-left buttons"
          v-if="release.distribution != 'Other countries'"
        >
          <md-button
            @click="close"
            class="md-primary md-raised large-width"
            v-if="editing"
          >
            <md-icon>arrow_left</md-icon>
            Back
          </md-button>

          <md-button
            type="submit"
            class="md-primary md-raised large-width"
            :disabled="loading"
          >
            <span v-if="!editing && !loading">Add &amp; Continue</span>
            <span v-if="editing && !loading">Save</span>
            <span v-if="loading">Loading...</span>
          </md-button>
        </div>
      </form>
    </section>
  </div>
</template>

<script>
import { mask } from "vue-the-mask";
import { mapActions } from "vuex";

export default {
  name: "NewRelease",
  directives: {
    mask
  },
  props: ["editing", "song"],
  metaInfo: function() {
    return {
      title: " - Add a release",
      meta: [
        {
          name: "description",
          content: "Create a new release"
        }
      ],
      link: [
        {
          vmid: "canonical",
          rel: "canonical",
          href: this.$store.getters["canonical"]()
        }
      ]
    };
  },
  data: function() {
    return {
      release: {
        album: "",
        record_number: "",
        upc: "",
        distribution: "",
        selected_types: [],
        release_date: null,
        copies: {}
      },
      copies: {
        physical: {},
        digital: {},
        ringtone: {},
        streaming: {}
      },
      errors: {},
      disabledTypes: [],
      loading: false
    };
  },
  computed: {
    selectedTypes: function() {
      return this.release.selected_types;
    },
    showUpcWarn: function() {
      if (this.release.upc && this.release.upc.length == 12) {
        return false;
      }

      return true;
    }
  },
  watch: {
    "release.upc": function(newValue) {
      if (newValue && newValue.length == 12) {
        delete this.errors.upc;
      }
    },
    "release.selected_types": function() {
      const types = ["physical", "digital", "ringtone", "streaming"];
      const unused = types.filter(
        x => !this.release.selected_types.includes(x)
      );

      if (this.release.selected_types.length == 0) {
        this.errors["types-empty"] = [];
        this.copies = {
          physical: {},
          digital: {},
          ringtone: {},
          streaming: {}
        };
        this.errors.copies = {};
      } else {
        for (const type of unused) {
          if (this.errors["types-empty"]) {
            const index = this.errors["types-empty"].indexOf(type);
            if (index >= 0) {
              this.errors["types-empty"].splice(index, 1);
            }
          }
          if (this.errors.copies) {
            delete this.errors.copies[type];
          }
          this.copies[type] = {};
        }
      }
    }
  },
  methods: {
    ...mapActions("release", ["createRelease", "updateRelease"]),
    close: function() {
      this.$emit("closeDialog");
    },
    copiesError: function(str) {
      if (this.errors.copies) {
        const [type, subtype] = str.split(".");

        if (typeof this.errors.copies[type] == "string") {
          return true;
        }
        if (this.errors.copies[type] && this.errors.copies[type][subtype]) {
          return true;
        }
      } else {
        return false;
      }
    },
    disabledDates: function(date) {
      const $date = this.$moment(date);
      const oneYear = this.$moment().add(1, "year");
      const oneMonth = this.$moment().subtract(1, "month");

      return $date.isBefore(oneMonth) || $date.isAfter(oneYear);
    },
    validateRelease: function() {
      this.errors = {};
      const copies = { ...this.copies };

      for (let type in copies) {
        for (let subtype in copies[type]) {
          let value = copies[type][subtype];

          if (value == "" || value == null) {
            delete copies[type][subtype];
          }
        }

        if (Object.keys(copies[type]).length == 0) {
          delete copies[type];
        }
      }

      const release = { ...this.release };
      release.copies = copies;

      release.release_date = this.$moment(release.release_date).format(
        "MM-DD-YYYY"
      );

      for (let key in release) {
        let value = release[key];

        if (/(album|distribution)/.test(key) && value == "") {
          this.errors[key] = "Required";
        } else if (key == "release_date") {
          if (value == "Invalid date") {
            this.errors[key] = "Invalid Date";
          } else if (
            this.$moment(release.release_date, "MM-DD-YYYY").isAfter(
              this.$moment().add(7, "years")
            )
          ) {
            this.errors[key] =
              "Release Date can't be further than 7 years from today";
          }
        } else if (key == "selected_types" && release[key].length == 0) {
          this.errors[key] = "You need to select at least one License Type";
        } else if (key == "copies") {
          delete this.errors["copies"];
          delete this.errors["types-empty"];

          const min = {
            streaming: 100,
            physical: 25,
            digital: 25,
            ringtone: 25
          };
          const max = {
            streaming: 10000,
            physical: 2500,
            digital: 2500,
            ringtone: 2500
          };

          this.errors[key] = {};
          this.errors["types-empty"] = [...release.selected_types];

          const totalCopies = type => {
            let total = 0;
            for (const subtype in release.copies[type]) {
              total = total + parseInt(release.copies[type][subtype]);
            }

            return total;
          };

          for (const type of release.selected_types) {
            this.errors["types-empty"].splice(
              this.errors["types-empty"].indexOf(type),
              1
            );

            if (totalCopies(type) > max[type]) {
              if (!this.errors[key][type]) {
                this.errors[key][type] = {};
              }

              this.errors[key][
                type
              ] = `The maximum number of copies for ${type} is ${max[type]}`;
            } else {
              for (const subtype in release.copies[type]) {
                if (parseInt(release.copies[type][subtype]) < min[type]) {
                  const error = `The minimum number of copies for ${subtype} is ${min[type]}`;

                  if (!this.errors[key][type]) {
                    this.errors[key][type] = {};
                  }
                  if (type == "physical") {
                    this.errors[key][type][subtype] = error;
                  } else {
                    this.errors[key][type] = error;
                  }
                }
              }
            }
          }

          if (Object.keys(this.errors["copies"]).length == 0) {
            delete this.errors["copies"];
          }
          if (this.errors["types-empty"].length == 0) {
            delete this.errors["types-empty"];
          }
        } else if (key == "upc" && value != null && value != "") {
          if (value.length < 12) {
            this.errors[key] = "UPC should be 12 digits";
          }
        }
      }

      if (Object.keys(this.errors).length == 0) {
        this.submitRelease(release);
      }
    },
    submitRelease: function(release) {
      this.loading = true;

      if (this.editing) {
        this.updateRelease(release)
          .then(() => {
            this.close();
            this.$toastr("success", "Release updated.");
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
            this.$toastr(
              "error",
              "There was an error updating your release. Please try again."
            );
          });
      } else {
        this.createRelease(release)
          .then(() => {
            setTimeout(() => {
              this.loading = false;
              this.$router.push({ name: "search" });
            }, 1000);
          })
          .catch(() => {
            this.loading = false;
            this.$toastr(
              "error",
              "There was an error creating your release. Please try again."
            );
          });
      }
    }
  },
  beforeMount: function() {
    if (this.editing) {
      if (this.song) {
        this.$store
          .dispatch("release/getRelease", this.song.releaseId)
          .then(response => {
            this.release = response;
            this.copies = Object.assign(this.copies, this.release.copies);
          });
      } else {
        let aux = JSON.parse(JSON.stringify(this.$store.state.release.current));
        this.release = { ...aux };
        this.copies = Object.assign(this.copies, { ...aux.copies });
      }
    }
  },
  mounted: function() {
    document.querySelector("#datepicker input").readOnly = true;
  }
};
</script>
<style lang="scss">
#new-release {
  h4 {
    position: relative;
    padding-bottom: 15px;

    .md-error {
      color: red;
      font-weight: normal;
      font-size: 12px;
      position: absolute;
      left: 0;
      bottom: 1px;

      ul {
        margin: 0;
        padding: 0;

        li {
          // display: inline-block;
          margin: 0;
          font-size: 12px;
        }
      }
    }
  }

  form {
    width: 100%;

    .form-group {
      margin-bottom: 25px;
      padding-bottom: 25px;

      .md-field {
        margin-bottom: 25px;
      }
    }
  }

  .md-button {
    width: 150px;
  }

  .license-types,
  .distribution,
  .n-copies {
    .md-invalid {
      color: red;

      .md-field {
        label,
        .md-input {
          border-color: red !important;
          color: red !important;
        }
      }
    }
  }
  .license-types {
    .md-checkbox {
      &.md-checked {
        .md-checkbox-container {
          background-color: transparent;

          &:after {
            border-color: black;
          }
        }
      }
    }
  }
  .international {
    border-radius: 3px;
    font-size: 1.4em;
    line-height: 1.4;
    padding: 10px;
    background-color: transparent;
    color: black;
    border: 1px solid red;

    p {
      margin: 0;
    }
  }
  .n-copies {
    .md-field {
      position: relative;
      justify-content: flex-start;

      label {
        color: #000;
        width: 120px;
      }
      .md-input {
        width: 100px;
      }

      &.md-invalid {
        label {
          color: #f00;
        }
        .md-error {
          position: absolute;
          left: 0;
          padding-top: 3px;
          width: 100%;
          text-align: center;
        }
      }
    }
    .md-error {
      position: static;
    }
  }
  .release-information {
    .md-field {
      label {
        flex: 0 0 auto;
      }
      .md-input {
        margin: 0;
      }

      &.album {
        justify-content: flex-start;
      }
      &.md-datepicker {
        label {
          order: 1;
        }
        .md-input {
          order: 2;
          width: 140px;
        }
        .md-icon {
          order: 3;
          margin: 4px 2px;
        }
        > .md-button {
          display: none;
        }
      }
    }
  }
  .md-field.md-invalid .md-error {
    right: initial;
    left: 15%;
  }
  .upc-isrc-warn {
    left: 15%;
    bottom: -18px;
  }
  .md-field.md-invalid .upc-isrc-warn {
    color: red;
  }

  &.editing {
    section.head {
      display: none;
    }
    section {
      padding-top: 20px;
    }
  }
}
</style>
