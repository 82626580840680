<template>
  <div id="my-cart">
    <section class="section head">
      <div class="container">
        <h1>CART</h1>

        <span>There are {{ cart.length }} items in your cart</span>
      </div>
    </section>

    <section class="section">
      <div class="container">
        <div class="reorder-tips" v-if="hasReorder">
          <div class="legend">Reorder Tips</div>
          <div class="text">
            <b
              >Please note that your song(s) may no longer be available in all
              chosen configurations,<br />
              it will only be added for those still available for licensing.</b
            >
            <br /><br />
            You can update any information that is hyperlinked below, such as
            Release name or Units, and changes will be automatically made.
            <br /><br />
            If you would like to add another composition click on a Release name
            or Unit count.
          </div>
        </div>

        <div class="md-layout">
          <div class="md-layout-item md-small-size-100">
            <h4 class="section-heading">YOUR SELECTIONS</h4>

            <div class="cart-results">
              <div class="empty-table" v-if="cart.length == 0">
                Cart is empty.
              </div>

              <div
                class="cart-results__item"
                v-else
                v-for="(item, index) in cart"
                :key="index"
              >
                <div class="cart-results-head">
                  <div class="result-col">Release</div>
                  <div class="result-col">Song Title</div>
                  <div class="result-col">Units</div>
                  <div class="result-col">Config</div>
                  <div class="result-col">Song Code</div>
                  <div class="result-col">Artist</div>
                  <div class="result-col">
                    Playtime

                    <router-link
                      target="_blank"
                      :to="{ name: 'faq', params: { question: '1' } }"
                      class="help-tooltip"
                    >
                      <md-icon>
                        help

                        <md-tooltip md-direction="top"
                          >How is the playtime calculated?</md-tooltip
                        >
                      </md-icon>
                    </router-link>
                  </div>
                  <div class="result-col">Royalty Rate</div>
                  <div class="result-col">Royalty Amount</div>
                  <div class="result-col">Processing Fee</div>
                  <div class="result-col">Remove</div>
                  <div class="result-col">Save For Later</div>
                </div>

                <div class="cart-results-body">
                  <div class="result-col">
                    <span class="result-col-label">Release</span>
                    <a @click="editRelease(item)">{{ item.release }}</a>
                  </div>

                  <div class="result-col">
                    <span class="result-col-label">Song Title</span>
                    {{ item.songTitle }}
                  </div>

                  <div class="result-col">
                    <span class="result-col-label">Units</span>
                    <a @click="editRelease(item)">{{ item.units }}</a>
                  </div>

                  <div class="result-col config">
                    <span class="result-col-label">Config</span>
                    {{ item.recordingType.substring(0, 4) }}
                  </div>

                  <div class="result-col">
                    <span class="result-col-label">Song Code</span>
                    {{ item.songCode }}
                  </div>

                  <div class="result-col">
                    <span class="result-col-label">Artist</span>
                    <a @click="editItem(item)">
                      {{ item.licenseRequest.artists }}
                    </a>
                  </div>

                  <div class="result-col">
                    <span class="result-col-label">Playtime</span>
                    <a @click="editItem(item)">
                      {{ item.licenseRequest.minutes | numeral("00") }}:{{
                        item.licenseRequest.seconds | numeral("00")
                      }}
                    </a>
                  </div>

                  <div class="result-col">
                    <span class="result-col-label">Royalty Rate</span>
                    {{ item.royaltyFee | numeral("$0,0.0000") }}
                  </div>

                  <div class="result-col">
                    <span class="result-col-label">Royalty Amount</span>
                    <strong>{{
                      item.royaltyAmount | numeral("$0,0.00")
                    }}</strong>
                  </div>

                  <div class="result-col">
                    <span class="result-col-label">Processing Fee</span>
                    <strong>{{
                      item.processingFee | numeral("$0,0.00")
                    }}</strong>
                  </div>

                  <div class="result-col col-btn">
                    <a @click="removeReleaseItem(item)">
                      <md-button class="md-raised md-red md-icon-button delete">
                        <md-icon>delete</md-icon>
                      </md-button>
                      <span class="result-col-label">Remove</span>
                    </a>
                  </div>

                  <div class="result-col col-btn">
                    <a @click="saveForLater(item)">
                      <md-button
                        class="md-raised md-primary md-icon-button delete"
                      >
                        <md-icon>bookmark</md-icon>
                      </md-button>
                      <span class="result-col-label">Save For Later</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div class="md-layout cart-footer">
              <div class="md-layout-item md-small-size-100 md-size-60">
                <md-button
                  class="md-raised md-primary large-width"
                  :to="{ name: 'new-release' }"
                >
                  <md-icon>add</md-icon>
                  Add A New Release
                </md-button>
              </div>
              <div
                class="md-layout-item md-small-size-100 md-size-40 cart-footer__list"
              >
                <div class="cart-footer-list">
                  <div class="cart-footer-list__item">
                    <span class="list-label">
                      <router-link
                        target="_blank"
                        :to="{ name: 'faq', params: { question: '1' } }"
                        class="help-tooltip"
                      >
                        <md-icon>
                          help

                          <md-tooltip md-direction="top">
                            How is the Royalty Amount calculated?
                          </md-tooltip>
                        </md-icon>
                      </router-link>
                      <strong>Royalty Amount Subtotal</strong>
                    </span>

                    <span class="list-label-num">
                      {{ royaltyAmountSubtotal | numeral("$0,0.00") }}
                    </span>
                  </div>
                  <div class="cart-footer-list__item">
                    <span class="list-label">
                      <router-link
                        target="_blank"
                        :to="{ name: 'faq', params: { question: '3' } }"
                        class="help-tooltip"
                      >
                        <md-icon>
                          help

                          <md-tooltip md-direction="top">
                            How is the Processing Fees calculated?
                          </md-tooltip>
                        </md-icon>
                      </router-link>
                      <strong>Processing Fees</strong>
                    </span>

                    <span class="list-label-num">
                      {{ processingFeesTotal | numeral("$0,0.00") }}
                    </span>
                  </div>
                  <div class="cart-footer-list__item">
                    <strong>Total</strong>
                    <span class="list-label-num">
                      <strong>{{ total | numeral("$0,0.00") }}</strong>
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div class="md-layout">
              <div class="md-layout-item md-small-size-100">
                <div class="cart-note">
                  <p>
                    Please note that your order is not complete and you are not
                    licensed until after your payment has been processed by HFA
                    and your license(s) have downloaded. You do not acquire any
                    actual or implied license by use of the shopping cart or
                    save cart features on the site.
                  </p>
                </div>
              </div>
            </div>

            <div class="md-layout">
              <div class="md-layout-item md-small-size-100 text-center">
                <md-button
                  class="md-raised md-primary"
                  :to="{ name: 'checkout' }"
                  :disabled="cart.length == 0"
                >
                  Proceed to Checkout
                </md-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section">
      <div class="container">
        <div class="md-layout">
          <div class="md-layout-item md-small-size-100">
            <h4 class="section-heading">MY SAVED ITEMS</h4>
            <p>Saved orders will expire 90 days after their last update.</p>

            <div class="cart-results">
              <div class="empty-table" v-if="saved.length == 0">
                No saved items.
              </div>

              <div
                class="cart-results__item"
                v-for="(item, index) in saved"
                :key="index"
              >
                <div class="cart-results-head">
                  <div class="result-col">Release</div>
                  <div class="result-col">Song Title</div>
                  <div class="result-col">Units</div>
                  <div class="result-col">Config</div>
                  <div class="result-col">Song Code</div>
                  <div class="result-col">Artist</div>
                  <div class="result-col">
                    Playtime

                    <a href="javascript:;" class="help-tooltip">
                      <md-icon>
                        help

                        <md-tooltip md-direction="top"
                          >How is the playtime calculated?</md-tooltip
                        >
                      </md-icon>
                    </a>
                  </div>
                  <div class="result-col">Royalty Rate</div>
                  <div class="result-col">Royalty Amount</div>
                  <div class="result-col">Remove</div>
                  <div class="result-col">Add to Cart</div>
                </div>

                <div class="cart-results-body">
                  <div class="result-col">
                    <span class="result-col-label">Release</span>
                    <a @click="editRelease(item)">{{ item.release }}</a>
                  </div>

                  <div class="result-col">
                    <span class="result-col-label">Song Title</span>
                    {{ item.songTitle }}
                  </div>

                  <div class="result-col">
                    <span class="result-col-label">Units</span>
                    <a @click="editRelease(item)">
                      <strong>{{ item.units }}</strong>
                    </a>
                  </div>

                  <div class="result-col config">
                    <span class="result-col-label">Config</span>
                    {{ item.config.substring(0, 4) }}
                  </div>

                  <div class="result-col">
                    <span class="result-col-label">Song Code</span>
                    {{ item.songCode }}
                  </div>

                  <div class="result-col">
                    <span class="result-col-label">Artist</span>
                    <a @click="editItem(item)">
                      {{ item.licenseRequest.artists }}
                    </a>
                  </div>

                  <div class="result-col">
                    <span class="result-col-label">Playtime</span>
                    <a @click="editItem(item)">
                      {{ item.licenseRequest.minutes }}:{{
                        item.licenseRequest.seconds
                      }}
                    </a>
                  </div>

                  <div class="result-col">
                    <span class="result-col-label">Royalty Rate</span>
                    {{ item.royaltyFee | numeral("$0,0.0000") }}
                  </div>

                  <div class="result-col">
                    <span class="result-col-label">Royalty Amount</span>
                    <strong>{{
                      item.royaltyAmount | numeral("$0,0.00")
                    }}</strong>
                  </div>

                  <div class="result-col col-btn">
                    <a @click="removeReleaseItem(item)">
                      <md-button class="md-raised md-red md-icon-button delete">
                        <md-icon>delete</md-icon>
                      </md-button>
                      <span class="result-col-label">Remove</span>
                    </a>
                  </div>

                  <div class="result-col col-btn">
                    <a @click="addToCart(item)">
                      <md-button
                        class="md-raised md-primary md-icon-button delete"
                      >
                        <md-icon>shopping_cart</md-icon>
                      </md-button>
                      <span class="result-col-label">Add to Cart</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- ------- DIALOGS ------- -->
    <md-dialog
      class="edit-release"
      :md-active.sync="showReleaseDetails"
      @md-closed="closeDialog"
    >
      <md-dialog-title>
        <span>EDIT RELEASE</span>

        <md-button
          class="md-raised md-dense add-button"
          @click="addSongToRelease(selectedItem)"
        >
          <md-icon>add</md-icon>
          Add Song
        </md-button>

        <md-button
          class="md-raised md-dense md-icon-button close"
          @click="showReleaseDetails = false"
        >
          <md-icon>clear</md-icon>
        </md-button>
      </md-dialog-title>

      <md-dialog-content>
        <NewRelease
          @closeDialog="showReleaseDetails = false"
          :editing="true"
          :song="selectedItem"
        />
      </md-dialog-content>
    </md-dialog>

    <md-dialog :md-active.sync="showLicenseRequest" @md-closed="closeDialog">
      <md-dialog-title>
        SONG CART
        <md-button
          class="md-raised md-dense md-icon-button close"
          @click="showLicenseRequest = false"
        >
          <md-icon>clear</md-icon>
        </md-button>
      </md-dialog-title>

      <md-dialog-content>
        <LicenseRequest
          :song="selectedItem"
          @closeDialog="showLicenseRequest = false"
        />
      </md-dialog-content>
    </md-dialog>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import NewRelease from "@/views/app/NewRelease.vue";
import LicenseRequest from "@/views/app/LicenseRequest.vue";

export default {
  name: "MyCart",
  components: {
    NewRelease,
    LicenseRequest
  },
  metaInfo: function() {
    return {
      title: " - Cart",
      meta: [
        {
          name: "description",
          content: "The items in your cart."
        }
      ],
      link: [
        {
          vmid: "canonical",
          rel: "canonical",
          href: this.$store.getters["canonical"]()
        }
      ]
    };
  },
  data: function() {
    return {
      selectedItem: null,
      showReleaseDetails: false,
      showLicenseRequest: false,
      editingRelease: true
    };
  },
  computed: {
    ...mapState({
      reorder: state => state.reorder,
      releases: state => state.release.releases,
      release: state => state.release.current
    }),
    ...mapGetters("release", [
      "royaltyAmountSubtotal",
      "processingFeesTotal",
      "total"
    ]),
    ...mapGetters("projects", ["hasReorder"]),
    cart: function() {
      return this.$store.getters["release/getAllCartItems"];
    },
    saved: function() {
      return this.$store.getters["release/getAllSavedItems"];
    }
  },
  methods: {
    closeDialog: function() {
      this.selectedItem = null;
    },
    editRelease: function(item) {
      this.selectedItem = item;
      this.showReleaseDetails = true;
    },
    editItem: function(item) {
      this.selectedItem = item;
      this.showLicenseRequest = true;
    },
    addSongToRelease: async function(item) {
      console.log(item);
      await this.$store.dispatch("release/setCurrentRelease", item.releaseId);
      this.$router.push({ name: "search" });
    },
    removeReleaseItem: function(item) {
      this.$store.dispatch("release/removeReleaseItem", item).then(() => {
        this.$toastr(
          "success",
          `Song <strong>${item.songTitle}</strong> was removed.`
        );
      });
    },
    saveForLater: function(item) {
      this.$store
        .dispatch("release/setItemFlag", { item: item, flag: "saved" })
        .then(() => {
          this.$toastr(
            "success",
            `Song <strong>${item.songTitle}</strong> saved for later.`
          );
        });
    },
    addToCart: function(item) {
      this.$store
        .dispatch("release/setItemFlag", { item: item, flag: "cart" })
        .then(() => {
          this.$toastr(
            "success",
            `Song <strong>${item.songTitle}</strong> moved back to cart.`
          );
        });
    },
    checkout: function() {
      this.showDialog = true;
    }
  },
  created: function() {
    this.$store.dispatch("release/recoverReleases");
  }
};
</script>

<style lang="scss">
#my-cart {
  .empty-cart {
    font-size: 2em;
    color: #999;
    font-weight: bold;
    margin: 40px 0 60px;
  }
  .help-tooltip {
    text-decoration: none;

    .md-icon {
      font-size: 20px !important;
      color: $color-teal;
    }
  }

  .reorder-tips {
    border: 1px solid #ccc;
    border-radius: 5px;
    position: relative;
    padding: 2em;
    margin: -40px 0 30px;

    .legend {
      position: absolute;
      top: 0;
      left: 0;
      padding: 10px;
      color: #fff;
      background-color: $color-green;
      transform: translate(50%, -50%);
      border-radius: 3px;
      font-size: 1.2em;
      font-weight: bold;
    }
    .text {
      font-size: 1.15em;
      text-align: center;
    }
  }

  .cart {
    &-section {
      position: relative;
    }

    &-note {
      font-family: $font-Gilroy-regular;
      border: 1px solid $color-black;
      color: $color-black;
      font-size: 12px;
      padding: 10px;
      text-align: center;
      margin-top: 20px;
      margin-bottom: 20px;

      p {
        font: inherit;
      }
    }

    &-results {
      display: flex;
      flex-wrap: wrap;
      margin-top: 30px;
      margin-bottom: 30px;

      .empty-table {
        font-size: 20px;
        font-family: $font-Gilroy-semibold;
        color: #999;
        text-align: center;
        border-top: 2px solid #e6e6e6;
        padding: 10px 0 0 0;
        width: 100%;
      }

      strong {
        font-family: $font-Gilroy-semibold;
        font-weight: $fw-semibold;
      }
      a {
        cursor: pointer;
      }

      .result {
        &-col {
          word-break: break-word;
          flex-basis: 0;
          flex-grow: 1;
          padding: 10px 4px;
          font-family: $font-Gilroy-regular;
          font-weight: $fw-regular;
          font-size: 16px;
          letter-spacing: -0.03em;

          &-label {
            font-family: $font-Gilroy-extrabold;
            font-weight: $fw-extrabold;
            color: $color-black;
            font-size: 16px;
            text-align: left;
            display: none;
          }

          &.config {
            text-transform: uppercase;
          }

          &.col-btn {
            justify-content: center;

            .md-button {
              width: 30px;
              min-width: 30px;
              height: 30px;

              .md-icon {
                font-size: 20px !important;
                min-width: auto;
                min-height: auto;
                height: auto;
                width: auto;
              }
            }

            > a {
              text-decoration: none;
              display: flex;
              align-items: center;
              cursor: pointer;
            }
          }
          .btn {
            .result-col-label {
              margin: 0;
              min-width: 30px;
            }
          }
          &:first-child,
          &:last-child {
            max-width: 90px;
          }
          &:last-child {
            text-align: center;
          }
        }

        &-group {
          display: flex;
          position: relative;
          padding: 0;
          background: $color-grey-4;
          width: 100%;
          max-width: 405px;
          flex: 2 0 auto;
          text-align: center;

          .result-col {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            max-width: none;
            flex-direction: column;
          }
          &_title {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            transform: translateY(-100%);
            font-family: $font-Gilroy-extrabold;
            font-weight: $fw-extrabold;
            background: $color-green;
            color: $color-white;
            text-align: center;
            padding: 5px;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
          }
        }
      }

      &-head,
      &-body {
        display: flex;
        width: 100%;

        & > .result-col {
          border-bottom: 1px solid $color-grey-3;

          &:nth-child(2) {
            flex-grow: 2;
          }
        }
      }
      &-body {
        .result-col:not(.result-group) {
          display: inline-flex;
          align-items: center;
        }
      }
      &-head {
        .result-col {
          font-family: $font-Gilroy-extrabold;
          font-weight: $fw-extrabold;
          color: $color-black;
        }
      }
      &__item {
        display: flex;
        flex-direction: column;
        width: 100%;

        [class*="-group_title"],
        [class*="-results-head"] {
          display: none;
        }

        &:first-child {
          [class*="-results-head"] {
            display: flex;
            [class*="-group_title"] {
              display: block;
            }
          }
        }
      }

      [class*="ico-"] {
        border-radius: 50%;
      }
      .ico {
        &-check {
          background: $color-green;
        }
        &-cross {
          background: $color-red;
        }
      }
    }

    &-footer {
      .btn {
        margin-right: 15px;

        &:last-child {
          margin: 0;
        }
      }
      &__list {
        text-align: right;
      }

      &-list {
        display: flex;
        flex-direction: column;
        width: 100%;

        &__item {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 15px;
          padding-left: 30px;

          [class*="ico-"] {
            position: absolute;
            left: 0;
            top: 0;
            margin-right: 10px;
          }
        }

        .list-label {
          display: inline-flex;
          align-items: center;
          justify-content: flex-start;
          font-family: $font-Gilroy-extrabold;
          font-weight: $fw-extrabold;
          font-size: 16px;
          margin-right: 10px;

          &-num {
            font-family: $font-Gilroy-regular;
            font-weight: $fw-regular;
          }

          .help-tooltip {
            position: absolute;
            left: 0;
          }
        }
      }
    }

    @media all and (max-width: 991px) {
      &-head {
        .form-label {
          padding-top: 0;
        }
      }

      &-results {
        display: flex;
        flex-wrap: wrap;
        padding-top: 0;
        margin-top: 25px;
        margin-bottom: 25px;

        .result {
          &-col {
            width: 100%;
            flex-grow: 1;
            border: none;
            flex-basis: auto;
            padding: 6px 4px;

            &,
            &-label {
              font-size: 13px;
            }
            &-label {
              display: inline-block;
              margin-right: 10px;
              min-width: 100px;
            }
            &:first-child,
            &:last-child {
              max-width: none;
            }

            &.col-btn {
              width: auto;
              text-align: center;
            }
          }
        }
        &-body {
          display: flex;
          flex-wrap: wrap;
        }
        &__item {
          display: flex;
          flex-direction: column;
          width: 100%;
          padding-bottom: 10px;
          margin-bottom: 10px;
          border-bottom: 1px solid $color-grey-3;

          .result-group_title {
            display: block;
          }

          &,
          &:first-child {
            [class*="-results-head"] {
              display: none;
            }
          }
        }
        .btn {
          &-favorite,
          &-plus {
            [class*="ico-"] {
              border-radius: 50%;
              background: $color-teal;
              margin-right: 10px;
            }

            &,
            &:hover {
              background: transparent;
            }

            &:hover {
              [class*="ico-"] {
                background: $color-teal-dark;
              }
            }
          }

          &-remove {
            background: $color-red;

            [class*="ico-"] {
              border-radius: 50%;
              background: $color-red;
              margin-right: 10px;
            }

            &,
            &:hover {
              background: transparent;
            }

            &:hover {
              [class*="ico-"] {
                background: $color-red-2;
              }
            }
          }
        }
      }

      &-checkout {
        &-agree {
          &-footer {
            margin: 15px 0;
            text-align: center;
          }
        }
        &-footer {
          text-align: center;
          margin: 15px 0;
        }
        $outspace: 55px;
        &-step {
          padding-left: 0;
          padding-top: 15px;
          padding-bottom: 15px;
          margin-bottom: 15px;

          &__title {
            padding-left: $outspace;
          }

          .step-number {
            transform: translate(0, -50%);
          }
        }

        &-info {
          [class*="item-"] {
            font-size: 13px;
          }
        }
      }
    }

    @media all and (max-width: 575px) {
      &-footer {
        .md-layout-item {
          text-align: center;
        }

        .btn {
          margin: 15px auto;

          &:last-child {
            margin: 0;
          }
        }

        &__list {
          margin-top: 20px;
        }
      }
    }
  }
}
</style>
