<template>
  <div id="account-form">
    <form novalidate @submit.prevent="create">
      <div class="container">
        <div class="table md-layout md-gutter fieldset" v-if="!checkout">
          <div class="legend">
            <span>User</span>
          </div>
          <div class="warning">* indicates required field</div>

          <div
            class="column md-layout-item md-size-50 md-small-size-100 md-layout"
          >
            <md-field
              class="md-layout md-layout-item md-size-100"
              :class="{ 'md-invalid': errors.first_name }"
            >
              <label
                class="md-layout-item md-size-40 md-small-size-100"
                for="first_name"
              >
                * First Name:
              </label>
              <md-input
                class="md-layout-item md-size-60 md-small-size-100"
                name="first_name"
                id="first_name"
                v-model="user.first_name"
              />
            </md-field>

            <md-field
              class="md-layout md-layout-item md-size-100"
              :class="{ 'md-invalid': errors.last_name }"
            >
              <label
                class="md-layout-item md-size-40 md-small-size-100"
                for="last_name"
              >
                * Last Name:
              </label>
              <md-input
                class="md-layout-item md-size-60 md-small-size-100"
                name="last_name"
                id="last_name"
                v-model="user.last_name"
              />
            </md-field>

            <md-field class="md-layout md-layout-item md-size-100">
              <label
                class="md-layout-item md-size-40 md-small-size-100"
                for="company"
              >
                Company:
              </label>
              <md-input
                class="md-layout-item md-size-60 md-small-size-100"
                name="company"
                id="company"
                v-model="user.company"
              />
            </md-field>

            <md-field
              class="md-layout md-layout-item md-size-100"
              :class="{ 'md-invalid': errors.referred }"
              v-if="register"
            >
              <label
                class="md-layout-item md-size-40 md-small-size-100"
                for="referred"
              >
                * Referred By:
              </label>
              <md-select v-model="user.referred" name="referred">
                <md-option value="search">Internet Search</md-option>
                <md-option value="ad">Advertisement</md-option>
                <md-option value="article">Article (Print or Online)</md-option>
                <md-option value="reference">Reference Book</md-option>
                <md-option value="event">Music Industry Event</md-option>
                <md-option value="marketing">Marketing Email</md-option>
                <md-option value="facebook">Facebook</md-option>
                <md-option value="twitter">Twitter</md-option>
                <md-option value="linkedin">LinkedIn</md-option>
                <md-option value="other">Other Social Media</md-option>
                <md-option value="other">Other</md-option>
              </md-select>
            </md-field>
          </div>

          <div
            class="column md-layout-item md-size-50 md-small-size-100 md-layout"
          >
            <div class="change-password" v-if="!register">
              <md-button
                :to="{ name: 'change-password' }"
                class="md-primary md-raised"
              >
                Change Password
              </md-button>
            </div>

            <md-field
              class="md-layout md-layout-item md-size-100"
              :class="{ 'md-invalid': errors.email }"
              v-if="register"
            >
              <label
                class="md-layout-item md-size-40 md-small-size-100"
                for="email"
              >
                * Email:
              </label>
              <md-input
                class="md-layout-item md-size-60 md-small-size-100"
                name="email"
                id="email"
                v-model="user.email"
              />
            </md-field>

            <md-field
              class="md-layout md-layout-item md-size-100"
              :class="{ 'md-invalid': errors.confirm_email }"
              v-if="register"
            >
              <label
                class="md-layout-item md-size-40 md-small-size-100"
                for="confirm_email"
              >
                * Confirm Email:
              </label>
              <md-input
                class="md-layout-item md-size-60 md-small-size-100"
                name="confirm_email"
                id="confirm_email"
                v-model="user.confirm_email"
              />
            </md-field>

            <md-field
              v-if="register"
              class="md-layout md-layout-item md-size-100"
              :class="{ 'md-invalid': errors.password }"
              :md-toggle-password="false"
            >
              <label
                class="md-layout-item md-size-40 md-small-size-100"
                for="password"
              >
                * Password:
              </label>
              <md-input
                class="md-layout-item md-size-60 md-small-size-100"
                type="password"
                name="password"
                id="password"
                v-model="user.password"
              />
              <div class="pwd-warn">
                Password must be at least 8 characters, and include UPPER/lower
                case, a number, and a special character (i.e. *&!#@)
              </div>
            </md-field>

            <md-field
              v-if="register"
              class="md-layout md-layout-item md-size-100"
              :class="{ 'md-invalid': errors.confirm_password }"
            >
              <label
                class="md-layout-item md-size-40 md-small-size-100"
                for="confirm_password"
              >
                * Confirm Password:
              </label>
              <md-input
                class="md-layout-item md-size-60 md-small-size-100"
                type="password"
                name="confirm_password"
                id="confirm_password"
                v-model="user.confirm_password"
              />
            </md-field>
          </div>
        </div>

        <div class="table md-layout md-gutter fieldset">
          <div class="legend">
            <span>Licensee</span>
          </div>
          <div class="warning">* indicates required field</div>

          <div
            class="column md-layout-item md-size-50 md-small-size-100 md-layout"
          >
            <md-field
              class="md-layout md-layout-item md-size-100"
              :class="{ 'md-invalid': errors.licensee_name }"
            >
              <label
                class="md-layout-item md-size-40 md-small-size-100"
                for="licensee_name"
              >
                * Licensee Name:</label
              >
              <md-input
                class="md-layout-item md-size-60 md-small-size-100"
                name="licensee_name"
                id="licensee_name"
                v-model="user.licensee_name"
              />
            </md-field>

            <md-field
              class="md-layout md-layout-item md-size-100"
              :class="{ 'md-invalid': errors.street_address }"
            >
              <label
                class="md-layout-item md-size-40 md-small-size-100"
                for="street_address_1"
              >
                * Street Address 1:
              </label>
              <md-input
                class="md-layout-item md-size-60 md-small-size-100"
                name="street_address_1"
                id="street_address_1"
                v-model="user.street_address"
              />
            </md-field>

            <md-field class="md-layout md-layout-item md-size-100">
              <label
                class="md-layout-item md-size-40 md-small-size-100"
                for="street_address_2"
              >
                Street Address 2:
              </label>
              <md-input
                class="md-layout-item md-size-60 md-small-size-100"
                name="street_address_2"
                id="street_address_2"
                v-model="user.street_address_two"
              />
            </md-field>

            <md-field
              class="md-layout md-layout-item md-size-100"
              :class="{ 'md-invalid': errors.phone_number }"
            >
              <label
                class="md-layout-item md-size-40 md-small-size-100"
                for="phone_number"
              >
                * Phone Number:
              </label>
              <md-input
                class="md-layout-item md-size-60 md-small-size-100"
                name="phone_number"
                id="phone_number"
                v-model="user.phone_number"
                v-mask="'##########'"
              />
            </md-field>
          </div>

          <div
            class="column md-layout-item md-size-50 md-small-size-100 md-layout"
          >
            <md-field class="md-layout md-layout-item md-size-100">
              <label
                class="md-layout-item md-size-40 md-small-size-100"
                for="country"
              >
                Country:
              </label>
              <md-select v-model="user.country" name="country">
                <md-option
                  v-for="(country, index) in countries"
                  :key="index"
                  :value="country['country code']"
                >
                  {{ country["country name"] }}
                </md-option>
              </md-select>
            </md-field>

            <md-field
              class="md-layout md-layout-item md-size-100"
              :class="{ 'md-invalid': errors.city }"
            >
              <label
                class="md-layout-item md-size-40 md-small-size-100"
                for="city"
              >
                * City:
              </label>
              <md-input
                class="md-layout-item md-size-60 md-small-size-100"
                name="city"
                id="city"
                v-model="user.city"
              />
            </md-field>

            <md-field
              class="md-layout md-layout-item md-size-100"
              :class="{ 'md-invalid': errors.state }"
            >
              <label
                class="md-layout-item md-size-40 md-small-size-100"
                for="state"
              >
                <span v-if="['USA', 'CAN'].includes(user.country)">* </span>
                State:
              </label>
              <md-select
                v-if="['USA', 'CAN'].includes(user.country)"
                v-model="user.state"
                name="state"
              >
                <md-option
                  v-for="(state, index) in filteredStates"
                  :key="index"
                  :value="state['state code']"
                >
                  {{ state["state_name"] }}
                </md-option>
              </md-select>

              <md-input
                v-else
                class="md-layout-item md-size-60 md-small-size-100"
                name="state"
                id="state"
                v-model="user.state"
              />
            </md-field>

            <md-field
              class="md-layout md-layout-item md-size-100"
              :class="{ 'md-invalid': errors.zip_code }"
            >
              <label
                class="md-layout-item md-size-40 md-small-size-100"
                for="zip_code"
              >
                <span v-if="['USA', 'CAN'].includes(user.country)">* </span>
                Zip/Postal Code:
              </label>
              <md-input
                class="md-layout-item md-size-60 md-small-size-100"
                name="zip_code"
                id="zip_code"
                v-model="user.zip_code"
              />
            </md-field>
          </div>
        </div>

        <p class="legal" v-if="register">
          By clicking "Accept" below, you, both individually and acting on
          behalf of any organization may represent in connection with your use
          of Songfile: (1) represent and warrant that you have the authority to
          enter into this agreement on behalf of any such organization; (2)
          represent and warrant that you are 18 years or older; (3) acknowledge
          that you have read and understand the
          <a href="https://www.harryfox.com/#/terms-of-use" target="_blank"
            >Terms of Use</a
          >, including the
          <a href="https://www.harryfox.com/#/privacy-policy" target="_blank"
            >HFA Privacy Policy</a
          >; and (4) agree that you and any organization you represent will be
          bound by all terms and conditions contained in the
          <a href="https://www.harryfox.com/#/terms-of-use" target="_blank"
            >Terms of Use</a
          >.
        </p>

        <md-button class="md-raised md-primary" @click="close" v-if="!register">
          <md-icon>arrow_left</md-icon>
          Back
        </md-button>

        <md-button
          class="md-raised md-primary"
          @click="update"
          v-if="!register"
        >
          Save Changes
        </md-button>

        <md-button
          type="submit"
          class="md-raised md-primary large-width"
          v-if="register"
        >
          Accept
        </md-button>
      </div>
    </form>
  </div>
</template>

<script>
import States from "@/services/states.js";
import { isEmpty } from "@/services/shared.js";
import { mask } from "vue-the-mask";

export default {
  name: "AccountForm",
  props: ["register", "checkout"],
  directives: {
    mask
  },
  data: function() {
    const countries = [
      { "country code": "USA", "country name": "United States of America" },
      { "country code": "CAN", "country name": "Canada" }
    ];

    return {
      errors: {},
      countries: countries,
      states: States,
      user: {
        licensee_name: null,
        street_address: null,
        street_address_two: null,
        first_name: null,
        last_name: null,
        password: null,
        confirm_password: null,
        email: null,
        confirm_email: null,
        zip_code: null,
        state: null,
        referred: null,
        phone_number: null,
        city: null,
        country: countries[0]["country code"]
      }
    };
  },
  computed: {
    editing: function() {
      return !this.register;
    },
    filteredStates: function() {
      const states = this.states.filter(state => {
        return state["country code"] == this.user.country;
      });
      return states;
    }
  },
  watch: {
    "user.country": function(newValue, oldValue) {
      if (!isEmpty(oldValue)) {
        this.user.state = "";
        this.user.city = "";
        this.user.zip_code = "";
      }
    }
  },
  methods: {
    requiredFields: function() {
      const fields = [
        "email",
        "confirm_email",
        "password",
        "confirm_password",
        "first_name",
        "last_name",
        "referred",
        "licensee_name",
        "street_address",
        "phone_number",
        "city",
        "state",
        "zip_code"
      ];

      if (this.editing) {
        fields.splice(0, 4);
      }

      if (!["USA", "CAN"].includes(this.user.country)) {
        fields.splice(-2);
      }

      return fields;
    },
    validate: function() {
      this.errors = {};

      if (this.editing && isEmpty(this.user.referred)) {
        this.user.referred = "search";
      }

      for (let key of this.requiredFields()) {
        let value = this.user[key];

        if (isEmpty(value)) {
          this.errors[key] = "Required field";
        } else if (key == "phone_number" && value.length < 10) {
          this.errors[key] = "Phone number should be 10 digits";
        }
      }

      if (Object.keys(this.errors).length > 0) {
        this.$toastr("error", "There are some fields with errors.");

        console.log("==============");
        console.log(this.errors);
        console.log("==============");

        return false;
      } else {
        return true;
      }
    },
    close: function() {
      this.$emit("closeDialog");
    },
    update: function() {
      if (!this.validate()) {
        return false;
      }

      this.$store
        .dispatch("auth/updateUser", this.user)
        .then(() => {
          this.$toastr("success", "User updated successfully.");
          this.$emit("closeDialog");
        })
        .catch(error => {
          this.$toastr("error", {
            msg: error.response.data["error"],
            timeout: 5000
          });
        });
    },

    create: function() {
      if (!this.validate()) {
        return false;
      }
      this.$store
        .dispatch("auth/createUser", this.user)
        .then(() => {
          this.$emit("created");
        })
        .catch(error => {
          this.$toastr("error", {
            msg: error.response.data["error"],
            timeout: 5000
          });
        });
    }
  },
  mounted: function() {
    if (!this.register) {
      this.user = { ...this.$store.state.auth.user };
      if (!this.countries.find(i => i["country code"] == this.user.country)) {
        this.user.country = this.countries[0]["country code"];
      }
      console.log("===================");
      console.log("USER", this.user);
      console.log("===================");
    }
  }
};
</script>
<style lang="scss">
#account-form {
  padding-top: 20px;

  @media (min-width: 961px) {
    min-width: 75vw;
  }

  .table {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    .column {
      font-size: 1.2em;
      display: flex;
      flex-wrap: wrap;

      .md-field {
        justify-content: flex-start;
        margin-bottom: 20px;
        padding: 0;

        label {
          font-weight: bold;
        }
        .md-input {
          padding: 0.3em 0.6em;
        }

        &.md-has-password {
          .md-button {
            border: 0;
            top: 1px;

            @media (max-width: 960px) {
              top: 24px;
            }
          }
        }
        .pwd-warn {
          font-size: 12px;
          line-height: 1em;
          text-align: left;
          position: absolute;
          bottom: -70%;
        }
      }

      > div {
        padding: 10px 0;
      }

      .label {
        font-weight: bold;
      }

      .change-password {
        width: 100%;
        text-align: center;

        .md-button {
          margin-top: 40px;
          font-size: 1.2em;
        }
      }
    }

    &.fieldset {
      border: 1px solid #e0e0e0;
      margin-top: 20px;
      margin-bottom: 40px;
      position: relative;

      .legend {
        width: 100%;
        font-size: 1.5em;
        font-weight: bold;

        span {
          background-color: #f6f6f6;
          border: 1px solid #e0e0e0;
          border-radius: 3px;
          display: inline-block;
          padding: 10px 30px;
          position: relative;
          top: -20px;
          left: 50px;
        }
      }
      .warning {
        position: absolute;
        right: 0;
        top: -20px;
        font-weight: bold;
      }
    }
  }

  > .md-button {
    width: 150px;
    margin: 10px 20px 0 0;
  }
}
</style>
