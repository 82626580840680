import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

// - Vue Meta
import VueMeta from "vue-meta";
Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
});

// - Vue Material
import VueMaterial from "vue-material";
import "vue-material/dist/vue-material.min.css";
// import "vue-material/dist/theme/default.css";
Vue.use(VueMaterial);

// - Deveodk/Toastr
import VueToastr from "@deveodk/vue-toastr";
import "@deveodk/vue-toastr/dist/@deveodk/vue-toastr.css";
Vue.use(VueToastr, {
  defaultPosition: "toast-top-right",
  defaultType: "success",
  defaultTimeout: 5000
});

// - MomentJS
import moment from "vue-moment";
Vue.use(moment);

// - Animation on Scroll (AOS)
import AOS from "aos";
import "aos/dist/aos.css";

Vue.config.productionTip = false;

// - Numerical Filter
import vueNumeralFilterInstaller from "vue-numeral-filter";
Vue.use(vueNumeralFilterInstaller, { locale: "en-au" });

// - Vue Html2PDF
import VueHtml2pdf from "vue-html2pdf";
Vue.use(VueHtml2pdf);

new Vue({
  router,
  store,
  created: function() {
    AOS.init();
  },
  render: function(h) {
    return h(App);
  }
}).$mount("#app");
