<template>
  <div id="license-request" class="container">
    <div class="configs-unavailable" v-if="configDisabled()">
      This song is not available in all chosen configurations, please note that
      it will only be added for the ones associated with this release.
    </div>
    <div class="block general-info">
      <div class="md-layout md-gutter">
        <div class="md-layout-item md-size-100">
          <strong>What is the play time for this new recording?</strong>

          <div class="md-layout md-gutter playtime">
            <div class="md-layout-item md-size-50">
              <md-field :class="{ 'md-invalid': errors.minutes }">
                <label>min</label>
                <md-input
                  id="request-minutes"
                  v-model="request.minutes"
                  v-mask="'##'"
                  placeholder="00"
                  @click="select($event)"
                ></md-input>
                <span class="md-error">{{ errors.minutes }}</span>
              </md-field>
            </div>

            <div class="md-layout-item md-size-50">
              <md-field :class="{ 'md-invalid': errors.seconds }">
                <label>sec</label>
                <md-input
                  id="request-seconds"
                  v-model="request.seconds"
                  v-mask="'##'"
                  placeholder="00"
                  @click="select($event)"
                ></md-input>
                <span class="md-error">{{ errors.seconds }}</span>
              </md-field>
            </div>
          </div>
        </div>

        <div class="md-layout-item md-size-60 md-small-size-100 artist">
          <strong>Whos is the artist for this new recording?</strong>

          <md-field class="md-layout" :class="{ 'md-invalid': errors.artists }">
            <label class="md-layout-item md-size-15 md-small-size-100">
              Artist
            </label>

            <md-input
              class="md-layout-item md-size-70 md-small-size-100"
              v-model="request.artists"
            />

            <span class="md-error">Required field</span>
          </md-field>
        </div>

        <div class="horizontal-line"></div>

        <div class="md-layout-item md-size-50 md-small-size-100 artist">
          <md-field class="md-layout" :class="{ 'md-invalid': errors.isrc }">
            <label class="md-layout-item md-size-15 md-small-size-100">
              ISRC
            </label>

            <md-input
              class="md-layout-item md-size-70 md-small-size-100"
              v-model="request.isrc"
              v-mask="'XXXXXXXXXXXX'"
            />

            <span class="upc-isrc-warn" v-show="showIsrcWarn">
              ISRC should be 12 characters
            </span>
          </md-field>
        </div>

        <div class="md-layout-item md-size-50 md-small-size-100 artist">
          <md-field class="md-layout">
            <label class="md-layout-item md-size-15 md-small-size-100">
              Label
            </label>

            <md-input
              class="md-layout-item md-size-70 md-small-size-100"
              v-model="request.label"
              maxlength="20"
            />
          </md-field>
        </div>
      </div>
    </div>

    <div class="buttons">
      <md-button class="md-raised md-primary large-width" @click="close">
        <md-icon>arrow_left</md-icon>
        Back
      </md-button>

      <md-button class="md-raised md-primary large-width" @click="save">
        <md-icon>add</md-icon>
        <span v-if="!editing">Add To Release</span>
        <span v-if="editing">Save</span>
      </md-button>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { mask } from "vue-the-mask";

export default {
  name: "LicenseRequest",
  directives: {
    mask
  },
  props: ["release", "song", "fromDetails"],
  data: function() {
    return {
      errors: {},
      editing: false,
      request: {
        minutes: "0",
        seconds: "00",
        artists: null,
        isrc: "",
        label: ""
      },
      cartRelease: null
    };
  },
  computed: {
    showIsrcWarn: function() {
      if (this.request.isrc && this.request.isrc.length == 12) {
        return false;
      }

      return true;
    }
  },
  watch: {
    "request.isrc": function(newValue) {
      if (newValue && newValue.length == 12) {
        delete this.errors.isrc;
      }
    }
  },
  methods: {
    ...mapActions("release", ["addItemToRelease", "updateReleaseItem"]),
    select: function(event) {
      event.target.select();
    },
    validate: function() {
      this.errors = {};

      for (let key in this.request) {
        if (key == "label") {
          continue;
        }

        let value = this.request[key];

        if (key == "isrc") {
          if (value == null || value == "") {
            continue;
          } else if (value.length < 12) {
            this.errors[key] = "ISRC should be 12 characters";
          }
        }

        if (value == null || value == "") {
          this.errors[key] = "Required field";
        }
      }

      if (
        parseInt(this.request.minutes) == 0 &&
        parseInt(this.request.seconds) == 0
      ) {
        this.errors["minutes"] = "Time can't be 00:00";
        this.errors["seconds"] = " ";
      }

      if (Object.keys(this.errors).length > 0) {
        this.$toastr("error", "There are some fields with errors.");
        return false;
      } else {
        return true;
      }
    },
    addToCart: function() {
      if (this.validate() === false) {
        return false;
      }

      this.song.licenseRequest = { ...this.request };

      if (this.editing) {
        this.updateReleaseItem(this.song).then(() => {
          this.$toastr(
            "success",
            `Song <strong>${this.song.songTitle}</strong> successfully updated.`
          );
        });
      } else {
        this.addItemToRelease({
          song: this.song,
          release: this.release,
          reloadCart: true
        }).then(() => {
          this.$toastr(
            "success",
            `Song <strong>${this.song.song_title}</strong> added to cart.`
          );
        });
      }
    },
    close: function() {
      this.$emit("closeDialog");
    },
    save: function() {
      if (this.addToCart() === false) {
        return false;
      }
      if (this.fromDetails == true) {
        this.$emit("completed");
      } else {
        this.$emit("closeDialog");
      }
    },
    configDisabled: function() {
      const release = this.release || this.cartRelease;

      if (release == null || release === undefined) {
        return false;
      }

      const configs = ["physical", "digital", "ringtone", "streaming"];

      for (const config of configs) {
        if (
          release.selected_types.includes(config) &&
          this.song[config] == "N"
        ) {
          return true;
        }
      }

      return false;
    }
  },
  mounted: function() {
    if (this.song.licenseRequest !== undefined) {
      this.request = { ...this.song.licenseRequest };
      this.editing = true;
    }

    document.getElementById("request-minutes").addEventListener("blur", () => {
      if (this.request.minutes == "" || this.request.minutes == null) {
        this.request.minutes = "0";
      }
    });

    document.getElementById("request-seconds").addEventListener("blur", () => {
      if (this.request.seconds == "" || this.request.seconds == null) {
        this.request.seconds = "00";
      }
    });
  },
  created: function() {
    if (
      this.editing == true &&
      (this.release == null || this.release === undefined)
    ) {
      this.$store
        .dispatch("release/getRelease", this.song.releaseId)
        .then(response => {
          this.cartRelease = response;
        });
    }
  }
};
</script>

<style lang="scss">
#license-request {
  @media all and (min-width: 576px) {
    min-width: 800px;
    font-size: 16px;
  }

  .upc-isrc-warn {
    bottom: -18px;
    left: 15%;
  }
  .md-field.md-invalid .upc-isrc-warn {
    color: red;
  }

  .configs-unavailable {
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    background-color: #ffe258;
    text-align: center;
    padding: 5px 0;
    font-size: 0.85em;
  }

  .md-error {
    // display: none;
  }
  .has-error {
    .md-error {
      display: inline-block;
      color: red;
      text-decoration: underline;
    }
  }
  h1 {
    color: $primary;
  }
  h2 {
    font-size: 1.25em;
    color: $primary;
  }
  .block {
    padding-bottom: 10px;
    border-bottom: 1px solid #ccc;

    .horizontal-line {
      width: 100%;
      height: 1px;
      background-color: #ccc;
      margin-bottom: 20px;
    }

    &.general-info {
      border: 0;
      font-size: 16px;

      strong {
        font-weight: normal;
        display: block;
        margin-top: 50px;
      }
      .artist {
        .md-field {
          justify-content: flex-start;

          .md-input {
            width: 300px;
          }
          .md-error {
            left: 0;
            right: initial;

            @media (min-width: 1224px) {
              left: 70px;
            }
          }
        }
      }
      .playtime {
        width: 300px;

        .md-field {
          label {
            order: 2;
            margin: 0;
            display: block;
            opacity: 1;
            font-size: 1em;
          }
          .md-input {
            order: 1;
            width: 60px;
            margin-right: 10px;
          }
          .md-error {
            left: 0;
            right: initial;
          }
        }
      }

      .md-field {
        label {
          color: #000;
          font-weight: normal;
          width: auto;
        }
        .md-input {
          border: 1px solid #ccc;
          width: auto;
        }
      }
    }
  }
  .label {
    font-weight: bold;
    font-size: 1.1em;
  }
  .downloads {
    .option {
      display: flex;
      align-items: flex-start;
      margin: 0 0 30px;

      &.n-downloads {
        margin-bottom: 10px;
      }
      div:first-child {
        width: 30%;
        min-width: 200px;
        margin: 0 30px 0 0;
      }
    }
  }
  .options {
    display: flex;
    flex-direction: column;

    .n-copies {
      display: flex;
      margin: 5px 0;
      align-items: center;
      justify-content: space-between;
      width: 35%;

      .md-checkbox {
        width: 30%;
        min-width: 120px;
      }
      .md-field {
        margin: 0;
        padding: 0;
        min-height: auto;
        width: 70%;
      }
    }
    .md-radio {
      margin: 10px 0;
    }
    .md-checkbox {
      margin: 0;
      text-transform: capitalize;
    }
  }
}
</style>
