import axios from "axios";

const state = {
  terms: {},
  status: "idle",
  results: [],
  number: 0
};

const getters = {
  totalResults: state => {
    return state.number;
  },
  yourSearch: function(state) {
    const terms = [];
    const keys = {
      title: "Song Title",
      artist: "Artist",
      writer: "Writer",
      song_code: "Song Code"
    };

    for (let key in state.terms) {
      if (state.terms[key] != null && state.terms[key] != "") {
        terms.push(`${keys[key]}: ${state.terms[key]}`);
      }
    }

    return terms.join("; ");
  }
};

const actions = {
  searchSongs({ commit }, search) {
    commit("clearResults");
    commit("changeStatus", "running");
    commit("setTerms", search);

    for (const key in search) {
      if (
        search[key] == null ||
        search[key] == "" ||
        search[key] == undefined
      ) {
        delete search[key];
      }
    }

    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.VUE_APP_API_URL}/songs`, search)
        .then(function(response) {
          commit("changeStatus", "done");

          const results = response.data.map(function(item) {
            item.license_type = {};
            item.song_title = item.title;
            item.song_code = item.song_code;

            if (item.physical == "Y") {
              item["license_type"].physical = true;
            }
            if (item.digital == "Y") {
              item["license_type"].digital = true;
            }
            if (item.ringtone == "Y") {
              item["license_type"].ringtone = true;
            }
            if (item.streaming_perc == "Y") {
              item["license_type"].streaming = true;
            }

            return item;
          });

          const final = Object.assign(
            { data: results },
            { headers: response.headers },
            { terms: search }
          );

          commit("pushResults", final);
          resolve(true);
        })
        .catch(error => {
          reject(error);
          if (error.response) {
            window.alert("Search Failed");
          }
        });
    });
  },
  emptyResults({ commit }) {
    commit("clearResults");
  }
};

const mutations = {
  changeStatus(state, status) {
    state.status = status;
  },
  clearResults(state) {
    state.number = 0;
    state.results = [];
  },
  pushResults(state, items) {
    state.results = items;
    state.number = items.headers["x-total"];
  },
  setTerms(state, search) {
    state.terms = search;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
