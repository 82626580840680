import Vue from "vue";
import Vuex from "vuex";
import auth from "./modules/auth";
import projects from "./modules/projects";
import release from "./modules/release";
import search from "./modules/search";
import faq from "./modules/faq";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    projects,
    release,
    search,
    faq
  },
  state: {
    path: "home",
    processingFee: 16,
    licenseeName: "THE MARY SUES"
  },
  mutations: {
    setLicenseeName(state, value) {
      state.licenseeName = value;
    }
  },
  actions: {},
  getters: {
    canonical: () => () => {
      return location.href;
    }
  }
});
