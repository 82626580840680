<template>
  <div id="receipt">
    <section class="section head">
      <div class="container">
        <h1>THANK YOU</h1>
      </div>
    </section>

    <section class="section">
      <div class="order-complete container">
        <h2>YOUR ORDER IS COMPLETE</h2>
        <p>
          Your confirmation number is <strong>{{ order }}</strong
          >. Your licenses are now available to download in the
          <router-link :to="{ name: 'my-projects' }">My Orders</router-link> tab
          in on your account page.
        </p>

        <div class="buttons">
          <md-button
            class="md-raised md-primary large-width"
            :to="{ name: 'home' }"
          >
            <md-icon>arrow_left</md-icon>
            Back To Home
          </md-button>
          <md-button
            class="md-raised md-primary large-width"
            :to="{ name: 'new-release' }"
          >
            Get Another License
          </md-button>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "Receipt",
  props: ["order"],
  metaInfo: function() {
    return {
      title: " - Your order is complete",
      meta: [
        {
          name: "description",
          content: "You can now download your license."
        }
      ],
      link: [
        {
          vmid: "canonical",
          rel: "canonical",
          href: this.$store.getters["canonical"]()
        }
      ]
    };
  }
};
</script>

<style lang="scss">
#receipt {
  .order-complete {
    .md-button {
      margin-top: 40px;
      margin-right: 20px;
    }
  }
}
</style>
