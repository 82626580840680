<template>
  <div id="song-details" class="container" v-if="Object.keys(song).length > 0">
    <div class="song-info">
      <div class="line md-layout">
        <div class="field md-layout-item md-size-100 md-layout">
          <span class="md-layout-item md-size-20 md-small-size-100">
            Song Code:
          </span>

          <span class="md-layout-item md-size-80 md-small-size-100">
            {{ song.song_code }}
          </span>
        </div>

        <div class="field md-layout-item md-size-100 md-layout">
          <span class="md-layout-item md-size-20 md-small-size-100">
            Song Title:
          </span>

          <span class="md-layout-item md-size-80 md-small-size-100">
            {{ song.song_title }}
          </span>
        </div>
      </div>

      <div class="line">
        <div class="field md-layout">
          <span class="md-layout-item md-size-20 md-small-size-100">
            Songwriter(s):
          </span>

          <span class="md-layout-item md-size-80 md-small-size-100">
            {{ showValue(song.writer) }}
          </span>
        </div>
      </div>

      <div class="line">
        <div class="field md-layout">
          <span class="md-layout-item md-size-20 md-small-size-100">
            Publisher(s):
          </span>

          <div
            class="md-layout-item md-size-80 md-small-size-100 pub-container"
          >
            <div
              class="pub"
              v-for="(publisher, i) in song.publisher.split('\r')"
              :key="i"
            >
              <span v-for="(pub, j) in publisher.trim().split('-')" :key="j">
                {{ pub.trim() }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="buttons">
      <md-button class="md-raised md-primary large-width" @click="close">
        <md-icon>arrow_left</md-icon>
        Back
      </md-button>

      <md-button
        class="md-raised md-primary large-width"
        @click="addToCart(song)"
        v-if="!isItemOnRelease(song.song_code) && !noConfig(song)"
      >
        <md-icon>add</md-icon>
        Add To Release
      </md-button>
    </div>

    <table v-if="song.album">
      <thead>
        <tr>
          <th>Artist</th>
          <th>Album</th>
        </tr>
      </thead>

      <tbody>
        <td>{{ song.artist }}</td>
        <td>{{ song.album }}</td>
      </tbody>
    </table>

    <!-- ------ DIALOGS ------ -->

    <md-dialog :md-active.sync="showLicenseRequest" @md-closed="closeDialog">
      <md-dialog-title>
        SONG CART
        <md-button
          class="md-raised md-dense md-icon-button close"
          @click="showLicenseRequest = false"
        >
          <md-icon>clear</md-icon>
        </md-button>
      </md-dialog-title>

      <md-dialog-content>
        <LicenseRequest
          :release="release"
          :song="selectedSong"
          :fromDetails="true"
          @closeDialog="showLicenseRequest = false"
          @completed="completed"
        />
      </md-dialog-content>
    </md-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import LicenseRequest from "@/views/app/LicenseRequest.vue";

export default {
  name: "SongDetails",
  components: {
    LicenseRequest
  },
  props: ["song_id", "noConfig", "release"],
  data: function() {
    return {
      song: {},
      showLicenseRequest: false,
      selectedSong: null
    };
  },
  computed: {
    ...mapGetters("release", ["isItemOnRelease"])
  },
  methods: {
    showValue: function(value) {
      if (value !== undefined && value !== null) {
        return value.replace(/,/g, ", ");
      }

      return " - ";
    },
    close: function() {
      this.$emit("closeDialog");
    },
    addToCart: function(item) {
      if (!this.isItemOnRelease(item.song_code)) {
        this.selectedSong = item;
        this.showLicenseRequest = true;
      } else {
        this.$toastr("error", "Item is already in the cart");
        return false;
      }
    },
    completed: function() {
      this.showLicenseRequest = false;
      this.$emit("closeDialog");
    },
    closeDialog: function() {
      this.selectedSong = null;
    }
  },
  mounted: function() {
    this.song = this.song_id;
  }
};
</script>

<style lang="scss">
#song-details {
  @media all and (min-width: 576px) {
    min-width: 800px;
    font-size: 16px;
  }

  .song-info {
    border-bottom: 1px solid #e6e6e6;
    padding: 2em 0 0;

    .line {
      .field {
        margin-bottom: 30px;

        > span {
          &:first-child {
            font-weight: bold;
            display: inline-block;
          }
        }
        .pub-container {
          overflow: auto;
          max-height: 88px;
        }
        .pub {
          margin-bottom: 2px;

          span {
            &:first-child {
              font-weight: bold;
            }
            &:last-child {
              &:before {
                content: " - ";
              }
            }
          }
        }
        ul {
          margin: 0;
          list-style: none;
          padding-left: 0;

          li {
            margin-bottom: 30px;

            &:last-child {
              margin: 0;
            }
          }
        }
      }
    }
  }

  .buttons {
    margin-bottom: 0;
  }

  table {
    width: 100%;
    margin-top: 30px;

    th,
    td {
      padding: 0.6em 2em;
    }

    thead {
      th {
        text-align: left;
        color: #fff;
        background-color: $green;
      }
    }
  }
}
</style>
