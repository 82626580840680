<template>
  <div id="home" class="landing">
    <section class="section landing-head">
      <div
        class="landing-head-soundwave"
        data-aos="fade-right"
        data-aos-easing="ease-in-out"
        data-aos-duration="1100"
      ></div>

      <div class="container">
        <div class="md-layout md-gutter">
          <div class="md-layout-item md-size-50 md-small-hide"></div>
          <div class="md-layout-item md-size-40 md-small-size-100">
            <h1
              class="section-heading"
              data-aos="fade-left"
              data-aos-delay="500"
              data-aos-duration="500"
            >
              THE EASY WAY TO LICENSE SONGS
            </h1>
            <p
              data-aos="fade-left"
              data-aos-delay="600"
              data-aos-duration="600"
            >
              Songfile helps you license cover songs for physical and digital
              formats such as CDs, downloads, ringtones and streams.
            </p>
            <div
              data-aos="fade-left"
              data-aos-delay="700"
              data-aos-duration="700"
            >
              <md-button
                class="large-width"
                v-show="isLoggedIn"
                :to="{ name: 'new-release' }"
              >
                Get A License
              </md-button>
              <md-button
                class="large-width"
                v-show="!isLoggedIn"
                :to="{ name: 'sign-up' }"
              >
                Register Now
              </md-button>
              <p
                data-aos="zoom-in"
                data-aos-duration="750"
                v-show="!isLoggedIn"
              >
                Already registered?
                <a class="login-link" @click="toggleLogin()">Log in here</a>.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section landing-section">
      <div class="container">
        <div class="md-layout md-gutter">
          <div
            class="md-layout-item md-size-33 md-small-size-100 landing-content"
          >
            <h2
              class="section-heading"
              data-aos="fade-left"
              data-aos-duration="500"
            >
              WHY DO I NEED A LICENSE?
            </h2>
            <p data-aos="fade-left" data-aos-duration="600">
              If you want to record a song or distribute the recording of that
              song that you do not own or control, U.S. Copyright Law requires
              that you get a mechanical license. This is required regardless of
              whether or not you are selling the copies that you make.
            </p>

            <div
              class="text-center text-sm-left"
              data-aos="fade-left"
              data-aos-duration="700"
            >
              <md-button
                :to="{ name: 'faq' }"
                class="md-raised md-green large-width"
              >
                Songfile FAQ
              </md-button>
            </div>
          </div>

          <div class="md-layout-item md-size-60 md-small-size-100 text-center">
            <img
              src="@/assets/img/songfile-landing-bands.png"
              class="landing-img-band img-fluid"
              data-aos="zoom-in"
            />
          </div>
        </div>
      </div>
    </section>

    <section class="section landing-section landing-section-how">
      <div class="container" id="how-it-works">
        <div class="md-layout md-gutter">
          <div class="md-layout-item md-size-100 text-center">
            <h2 class="section-heading" data-aos="fade-up">
              HOW DOES IT WORK?
            </h2>
          </div>

          <div class="md-layout-item md-size-100 text-center">
            <div class="md-layout md-gutter">
              <div
                class="md-layout-item md-small-size-100 md-size-33 how-item"
                data-aos="fade-up"
                data-aos-delay="50"
              >
                <div class="how-item-circle">
                  <div class="how-item-circle-num">1</div>
                  <img
                    src="@/assets/img/how-it-work/hiw-1.svg"
                    class="how-item-circle-img"
                    alt="HOW IT WORKS"
                    title="HOW IT WORKS"
                  />
                </div>
                <div class="how-item-heading">CREATE AN ACCOUNT</div>
                <!-- <div class="how-item-descr">
                  <p>
                    Lorem ipsum dolor sit amet, consect etur adipiscing elit.
                    Nam nec egestas enim. Praesent in pretium nulla.
                  </p>
                </div> -->
              </div>

              <div
                class="md-layout-item md-small-size-100 md-size-33 how-item"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div class="how-item-circle">
                  <div class="how-item-circle-num">2</div>
                  <img
                    src="@/assets/img/how-it-work/hiw-2.svg"
                    class="how-item-circle-img"
                    alt="HOW IT WORKS"
                    title="HOW IT WORKS"
                  />
                </div>
                <div class="how-item-heading">ADD A RELEASE</div>
                <!-- <div class="how-item-descr">
                  <p>
                    Lorem ipsum dolor sit amet, consect etur adipiscing elit.
                    Nam nec egestas enim. Praesent in pretium nulla.
                  </p>
                </div> -->
              </div>

              <div
                class="md-layout-item md-small-size-100 md-size-33 how-item"
                data-aos="fade-up"
                data-aos-delay="150"
              >
                <div class="how-item-circle">
                  <div class="how-item-circle-num">3</div>
                  <img
                    src="@/assets/img/how-it-work/hiw-3.svg"
                    class="how-item-circle-img"
                    alt="HOW IT WORKS"
                    title="HOW IT WORKS"
                  />
                </div>
                <div class="how-item-heading">SEARCH FOR SONGS</div>
                <!-- <div class="how-item-descr">
                  <p>
                    Lorem ipsum dolor sit amet, consect etur adipiscing elit.
                    Nam nec egestas enim. Praesent in pretium nulla.
                  </p>
                </div> -->
              </div>
            </div>

            <div class="md-layout md-gutter">
              <div
                class="md-layout-item md-small-size-100 md-size-33 how-item"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <div class="how-item-circle">
                  <div class="how-item-circle-num">4</div>
                  <img
                    src="@/assets/img/how-it-work/hiw-4.svg"
                    class="how-item-circle-img"
                    alt="HOW IT WORKS"
                    title="HOW IT WORKS"
                  />
                </div>
                <div class="how-item-heading">MAKE YOUR SELECTIONS</div>
                <!-- <div class="how-item-descr">
                  <p>
                    Lorem ipsum dolor sit amet, consect etur adipiscing elit.
                    Nam nec egestas enim. Praesent in pretium nulla.
                  </p>
                </div> -->
              </div>

              <div
                class="md-layout-item md-small-size-100 md-size-33 how-item"
                data-aos="fade-up"
                data-aos-delay="250"
              >
                <div class="how-item-circle">
                  <div class="how-item-circle-num">5</div>
                  <img
                    src="@/assets/img/how-it-work/hiw-5.svg"
                    class="how-item-circle-img"
                    alt="HOW IT WORKS"
                    title="HOW IT WORKS"
                  />
                </div>
                <div class="how-item-heading">PURCHASE SONG LICENSES</div>
                <!-- <div class="how-item-descr">
                  <p>
                    Lorem ipsum dolor sit amet, consect etur adipiscing elit.
                    Nam nec egestas enim. Praesent in pretium nulla.
                  </p>
                </div> -->
                <div class="how-item-descr__help">
                  <md-icon>help</md-icon>
                  <router-link
                    :to="{ name: 'faq', params: { question: '3' } }"
                    target="_blank"
                    class="help-link"
                  >
                    How much is a song license?
                  </router-link>
                </div>
              </div>

              <div
                class="md-layout-item md-small-size-100 md-size-33 how-item"
                data-aos="fade-up"
                data-aos-delay="300"
              >
                <div class="how-item-circle">
                  <div class="how-item-circle-num">6</div>
                  <img
                    src="@/assets/img/how-it-work/checkmark.svg"
                    class="how-item-circle-img approval"
                    alt="HOW IT WORKS"
                    title="HOW IT WORKS"
                  />
                </div>
                <div class="how-item-heading">GET FINAL APPROVAL</div>
                <!-- <div class="how-item-descr">
                  <p>
                    Lorem ipsum dolor sit amet, consect etur adipiscing elit.
                    Nam nec egestas enim. Praesent in pretium nulla.
                  </p>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section
      class="section landing-section landing-section-register"
      v-show="!isLoggedIn"
    >
      <div class="container">
        <div class="md-layout md-gutter">
          <div class="md-layout-item md-small-size-100 md-size-33">
            <h2
              class="section-heading"
              data-aos="fade-left"
              data-aos-duration="500"
              style="text-align: center;"
            >
              REGISTER FOR FULL ACCESS
            </h2>
            <!-- <p data-aos="fade-left" data-aos-duration="600">
              Lorem ipsum dolor sit amet, consectetur adip iscing elit. Nam nec
              egestas enim. Praesent in pretium nulla.
            </p> -->

            <div
              class="text-center text-sm-left"
              data-aos="fade-left"
              data-aos-duration="700"
            >
              <md-button
                class="md-raised md-green large-width"
                :to="{ name: 'sign-up' }"
              >
                Register Now
              </md-button>
            </div>

            <p
              data-aos="zoom-in"
              data-aos-duration="750"
              style="text-align: center;"
            >
              Already registered?
              <a class="login-link" @click="toggleLogin()">Log in here</a>.
            </p>
          </div>

          <div class="md-layout-item md-size-10 md-small-hide"></div>

          <div class="md-layout-item md-small-size-100 md-size-50 text-center">
            <img
              src="@/assets/img/songfile-landing-headphones.png"
              class="landing-img-headphones img-fluid"
              data-aos="zoom-in"
            />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Home",
  props: ["authError"],
  metaInfo: function() {
    return {
      title: "Songfile - The easy way to license songs",
      titleTemplate: "%s",
      meta: [
        {
          name: "description",
          content:
            "Songfile helps you license cover songs for physical and digital formats such as CDs, downloads, ringtones and streams."
        }
      ],
      link: [
        {
          vmid: "canonical",
          rel: "canonical",
          href: this.$store.getters["canonical"]()
        }
      ]
    };
  },
  computed: {
    ...mapGetters("auth", ["isLoggedIn"])
  },
  methods: {
    toggleLogin: function() {
      this.$emit("showLogin");
    }
  },
  mounted: function() {
    console.log(this.$store.getters["canonical"]());
    if (this.$route.params.authError) {
      this.$toastr("warning", "You're not authenticated.");
    }
  }
};
</script>

<style lang="scss">
a {
  cursor: pointer;
}

#home.landing {
  .md-button {
    .md-button-content {
      font-weight: bold;
    }
  }
  .landing {
    &-head {
      position: relative;
      // background: $color-teal url('../assets/img/landing-head-soundwave.png') no-repeat 0 50%;
      background: linear-gradient(160deg, $color-green, $color-teal);
      background-size: cover;
      height: auto;
      min-height: 600px;
      padding-bottom: 13vw;
      display: flex;
      flex-wrap: wrap;

      *:not(.md-button):not(.md-button-content) {
        transition-timing-function: ease-in-out;
        color: $color-white;

        .btn {
          margin-top: 30px;
        }
      }
      .login-link {
        text-decoration: underline;
      }
      .container,
      .md-layout,
      .md-layout-item {
        height: 100%;
      }
      .section-heading {
        color: $color-white;
        margin: 20px 0 0;
        & + * {
          margin-top: 30px;
        }
      }

      &:after {
        content: "";
        position: absolute;
        pointer-events: none;
        left: 0;
        right: 0;
        bottom: 0;
        height: 12vw;
        width: 100%;
        background: transparent url("../assets/img/landing-head-mask.png")
          no-repeat 50% 0;
        background-size: 100%;
        z-index: 10;
      }

      &-soundwave {
        background: transparent url("../assets/img/landing-head-soundwave.png")
          no-repeat 100% 50%;
        background-size: 100% 100%;
        position: absolute;
        pointer-events: none;
        width: 50%;
        height: 100%;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        z-index: 5;

        &[data-aos] {
          transition-timing-function: ease-in-out;
          //transition-duration: 1100ms;
          transition-property: opacity, transform, -webkit-transform, height !important;
          transition-delay: 0.1s, 0.1s, 0.1s, 0.6s !important;
          transition-duration: 1100ms, 1100ms, 1100ms, 1100ms !important;
          height: 0;
        }

        &.aos-animate {
          height: 100%;
        }
      }

      @media all and (max-width: 960px) {
        &-soundwave {
          opacity: 0.5;
          z-index: -1;
          width: 100%;
        }

        p {
          font-weight: bold;
        }
      }
    }

    &-section {
      margin-top: -10px;
      padding-top: 100px;

      @media all and (max-width: 960px) {
        margin-top: 10px;
      }

      .btn {
        margin-top: 15px;
        margin-bottom: 30px;
      }

      .section-heading {
        font-size: 48px;
        margin-bottom: 0.5em;
      }

      &-how {
        position: relative;
        background: $color-teal;
        z-index: 1;
        padding-top: 0;
        margin-bottom: 50px;

        &:before,
        &:after {
          content: "";
          position: absolute;
          pointer-events: none;
          left: 0;
          right: 0;
          height: 12vw;
          width: 100%;
          background-color: transparent;
          background-image: url("../assets/img/landing-head-how-wave.png");
          background-repeat: no-repeat;
          background-size: 100%;
          z-index: -1;
        }
        &:before {
          top: -11vw;
          height: 12vw;
          background-position: 50% 0;
          background-size: 100%;
        }
        &:after {
          bottom: -16vw;
          height: 18vw;
          background-position: 50% 100%;
          background-size: 100%;
        }

        * {
          color: $color-white;
        }

        #how-it-works {
          padding-top: 80px;
          // transform: translateY(-80px);
        }

        .md-layout-item {
          padding: 0 3.3%;
        }

        .how-item {
          position: relative;
          margin: 30px 0;
          text-align: center;

          &-circle {
            position: relative;
            display: flex;
            width: 146px;
            height: 146px;
            margin: 15px auto 30px;
            background: $color-green;
            border-radius: 50%;
            align-items: center;
            justify-content: center;

            img,
            &-img {
              display: inline-flex;
              width: auto;
              height: auto;
              max-width: 60px;
              max-height: 60px;

              &.approval {
                width: 70px;
                max-width: none;
                max-height: none;
              }
            }

            &-num {
              display: inline-flex;
              position: absolute;
              top: 5px;
              left: 5px;
              width: 40px;
              height: 40px;
              align-items: center;
              border-radius: 50%;
              justify-content: center;
              background: $color-white;
              font-family: $font-Gilroy-extrabold;
              font-weight: $fw-extrabold;
              color: $color-black;
            }
          }

          &-heading {
            font-family: $font-Gilroy-extrabold;
            font-weight: $fw-extrabold;
            font-size: 18px;
            line-height: 1.5;
            margin-bottom: 15px;
            color: $color-white;
          }

          &-descr {
            font-family: $font-Gilroy-regular;
            font-weight: $fw-regular;
            font-size: 14px;
            line-height: 1.5;
            margin-bottom: 20px;
            color: $color-white;

            &__help {
              position: relative;
              text-align: center;
              a,
              .help-link {
                font-family: $font-Gilroy-extrabold;
                font-weight: $fw-extrabold;
                font-size: 14px;
                line-height: 1.5;
                color: $color-green-light;
                display: inline-block;
              }

              .md-icon {
                color: $color-green;
              }
            }
          }
        }
      }

      &-register {
        position: static;
      }
    }

    &-img {
      margin: 0 auto;

      &-band,
      &-headphones {
        position: relative;
        z-index: -1;
        margin-top: -100px;

        @media all and (max-width: 960px) {
          margin-top: 0;
        }
      }
      &-headphones {
        z-index: 2;
      }
    }

    @media all and (max-width: 991px) {
      &-head {
        min-height: 400px;
      }
      &-section {
        .btn {
          margin-top: 25px;
          margin-bottom: 25px;
        }

        .section-heading {
          font-size: 37px;
        }
      }
      &-content {
        margin: 0 0 40px;
      }
    }

    @media all and (max-width: 767px) {
      &-section {
        .section-heading {
          font-size: 32px;
        }
      }
    }
  }
}
</style>
