<template>
  <div id="my-projects" class="account-container container">
    <ProfileNav />

    <div class="header">
      <h1>MY ORDERS ({{ projects.length }})</h1>
      <!-- <md-field>
        <md-select v-model="sortBy" placeholder="Sort by...">
          <md-option value="date">Date</md-option>
          <md-option value="order_number">Order</md-option>
          <md-option value="total">Total</md-option>
        </md-select>
      </md-field> -->
    </div>

    <div class="orders">
      <div class="order" v-for="(order, index) in projects" :key="index">
        <input type="checkbox" :id="`order-${index}`" />

        <div class="line">
          <div class="details">
            <div class="field">
              <strong>Order:</strong>
              <span>{{ order.braintree_id || order.order_number }}</span>
            </div>

            <div class="field">
              <strong>Purchase Date:</strong>
              <span>
                {{ [order.license_date, "DD-MMM-YY"] | moment("ll") }}
              </span>
            </div>

            <div class="field">
              <strong>Total</strong>
              <span>{{ orderTotal(order) | numeral("$0,0.00") }}</span>
            </div>
          </div>

          <div class="more">
            <label :for="`order-${index}`">
              <span @click="loadItems(order)">
                view details
                <md-icon>add_circle</md-icon>
              </span>

              <span>
                close
                <md-icon>remove_circle</md-icon>
              </span>
            </label>
          </div>
        </div>

        <div class="items-container">
          <div class="loading" v-if="!order.items.length">
            <md-progress-spinner
              md-mode="indeterminate"
              :md-diameter="20"
              :md-stroke="3"
            ></md-progress-spinner>
            &nbsp;Loading...
          </div>
          <div class="items" v-if="order.items.length">
            <md-table v-model="order.items" class="cart-table">
              <md-table-row slot="md-table-row" slot-scope="{ item }">
                <md-table-cell md-label="License Number">
                  <span v-if="hasVoid(order)">
                    {{ item.licenseNumber }}
                  </span>

                  <a @click="licenseLink(order, item)" v-else>
                    {{ item.licenseNumber }}
                    <md-tooltip md-direction="top">Download License</md-tooltip>
                  </a>
                </md-table-cell>

                <md-table-cell md-label="Release" md-sort-by="release">
                  {{ item.release }}
                </md-table-cell>

                <md-table-cell md-label="Song Title">
                  {{ item.songTitle }}
                </md-table-cell>

                <md-table-cell md-label="Units" class="units">
                  {{ item.units }}
                </md-table-cell>

                <md-table-cell md-label="Config" class="config">
                  {{ item.config.substring(0, 4) }}
                </md-table-cell>

                <md-table-cell md-label="Song Code">
                  {{ item.songCode }}
                </md-table-cell>

                <md-table-cell md-label="Artist">
                  {{ item.licenseRequest.artists }}
                </md-table-cell>

                <md-table-cell md-label="Playtime">
                  {{ item.licenseRequest.minutes | numeral("00") }}:{{
                    item.licenseRequest.seconds | numeral("00")
                  }}
                </md-table-cell>

                <md-table-cell md-label="Royalty Rate">
                  {{ item.royaltyFee | numeral("$0,0.0000") }}
                </md-table-cell>

                <md-table-cell md-label="Royalty Amount">
                  <strong>{{ item.royaltyAmount | numeral("$0,0.00") }}</strong>
                </md-table-cell>

                <md-table-cell md-label="Processing Fee">
                  <strong>{{ item.processingFee | numeral("$0,0.00") }}</strong>
                </md-table-cell>

                <md-table-cell md-label="Voided" v-if="hasVoid(order)">
                  <span class="voided">
                    {{ item.voidedAmount | numeral("$0,0.00") }}
                  </span>
                </md-table-cell>
              </md-table-row>
            </md-table>

            <div class="table-footer">
              <div>
                <ReOrderBtn :order="order" />
              </div>
              <div class="total">
                <div class="subtotal">
                  <div class="name">Total Royalty Amount</div>
                  <div class="value">
                    {{ order.total_amt | numeral("$0,0.00") }}
                  </div>
                </div>

                <div class="subtotal">
                  <div class="name">Total Processing Fees</div>
                  <div class="value">
                    {{ order.total_processing_fee | numeral("$0,0.00") }}
                  </div>
                </div>

                <div class="subtotal" v-if="hasVoid(order)">
                  <div class="name">Total Voided Amount</div>
                  <div class="value voided">
                    {{ order.total_void | numeral("$0,0.00") }}
                  </div>
                </div>

                <div>
                  <div class="name">Total With Processing Fees</div>
                  <div class="value">
                    {{ orderTotal(order) | numeral("$0,0.00") }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProfileNav from "@/components/ProfileNav.vue";
import ReOrderBtn from "@/components/ReOrderBtn.vue";

export default {
  name: "MyProjects",
  components: {
    ProfileNav,
    ReOrderBtn
  },
  metaInfo: function() {
    return {
      title: " - My Orders",
      meta: [
        {
          name: "description",
          content: "See all your past orders."
        }
      ],
      link: [
        {
          vmid: "canonical",
          rel: "canonical",
          href: this.$store.getters["canonical"]()
        }
      ]
    };
  },
  data: function() {
    return {
      sortBy: "date",
      projects: []
    };
  },
  methods: {
    licenseLink: function(order, item) {
      this.$store
        .dispatch("projects/getPdfLink", {
          order: order.order_number,
          license: item.licenseNumber
        })
        .then(response => {
          window.open(response);
        });
    },
    loadItems: function(order) {
      if (order.items.length) {
        return false;
      }

      this.$store.dispatch("projects/orderDetails", order).then(response => {
        order.items = response;
      });
    },
    hasVoid: function(order) {
      return order.total_void != 0;
    },
    isHistorical: function(order) {
      return order.braintree_id == undefined;
    },
    orderTotal: function(order) {
      return order.total_amt + order.total_processing_fee + order.total_void;
    }
  },
  created: function() {
    const sortByDate = (a, b) => {
      const aDate = this.$moment(a.license_date, "DD-MMM-YY");
      const bDate = this.$moment(b.license_date, "DD-MMM-YY");

      if (aDate.isBefore(bDate)) {
        return 1;
      }
      if (bDate.isBefore(aDate)) {
        return -1;
      }

      return 0;
    };

    if (this.$store.getters["auth/isLoggedIn"]) {
      this.$store.dispatch("projects/getProjects").then(() => {
        this.projects = this.$store.state.projects.list.sort(sortByDate);
      });
    }
  }
};
</script>

<style lang="scss">
#my-projects {
  .orders {
    display: flex;
    flex-direction: column;

    .order {
      border-top: 1px solid #ccc;
      position: relative;

      > input[type="checkbox"] {
        display: none;
        position: absolute;
      }

      .line {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 20px 0;

        .details {
          display: flex;
          align-items: center;

          .field {
            width: 150px;
            font-size: 1.2em;

            strong {
              margin-right: 5px;
            }

            &:nth-child(2) {
              width: 250px;
            }
          }
        }

        .more {
          label {
            cursor: pointer;
            font-weight: bold;
            color: $blue;

            .md-icon {
              color: $blue;
            }

            span:last-child {
              display: none;
            }
          }
        }
      }

      .items-container {
        display: none;
        background-color: #f0f0f0;
        width: 100%;
        position: relative;
        padding: 10px 0;

        .loading {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 20px;
        }
        .items {
          // width: 70vw;
          margin: 0 20px;
          background-color: transparent;

          .md-table,
          .md-table * {
            background-color: transparent;
          }

          .voided {
            color: red;
          }

          .table-footer {
            .total {
              > div {
                .name {
                  margin-left: 0;
                  width: 200px;
                  text-align: right;
                }
                .value {
                  margin-left: 50px;
                }
              }
              .subtotal {
                font-size: 0.9em;

                .name {
                  font-weight: normal;
                }
              }
            }
          }
        }
      }

      input:checked ~ .line .more label span {
        &:first-child {
          display: none;
        }
        &:last-child {
          display: block;
        }
      }
      input:checked ~ .items-container {
        display: block;
      }

      &:last-child {
        border-bottom: 1px solid #ccc;
      }
    }
  }
}
</style>
