import Vue from "vue";
import Router from "vue-router";
import Home from "./views/Home.vue";
import Maintenance from "./views/Maintenance.vue";
import store from "./store";

Vue.use(Router);

let routes = [
  {
    path: "",
    component: () => import("@/MaterialApp.vue"),
    props: true,
    children: [
      {
        path: "",
        component: () => import("@/layout/App.vue"),
        props: true,
        children: [
          {
            path: "/",
            name: "home",
            component: Home
          },
          {
            path: "/login",
            redirect: { name: "home" }
          },
          {
            path: "/faq/:question?",
            name: "faq",
            component: () => import("@/views/Faq.vue")
          },
          {
            path: "/public-search",
            name: "public-search",
            component: () => import("@/views/PublicSearch.vue")
          },
          {
            path: "/sign-up",
            name: "sign-up",
            component: () => import("@/views/Signup.vue")
          },
          {
            path: "/account-recovery",
            name: "account-recovery",
            component: () => import("@/views/AccountRecovery.vue")
          },
          {
            path: "/change-password",
            name: "change-password",
            component: () => import("@/views/AccountRecovery.vue")
          },
          {
            path: "/account-confirmation",
            name: "account-confirmation",
            component: () => import("@/views/AccountConfirmation.vue")
          },
          {
            path: "/account-change",
            name: "account-change",
            component: () => import("@/views/AccountChange.vue")
          },
          {
            path: "/contact-us",
            name: "contact-us",
            component: () => import("@/views/ContactUs.vue")
          }
        ]
      },
      {
        path: "app",
        redirect: { name: "my-profile" }
      },
      {
        path: "/app/",
        component: () => import("@/layout/App.vue"),
        props: true,
        meta: {
          requiresAuth: true
        },
        children: [
          {
            path: "new-release",
            name: "new-release",
            component: () => import("@/views/app/NewRelease.vue")
          },
          {
            path: "search",
            name: "search",
            component: () => import("@/views/app/SongSearch.vue")
          },
          {
            path: "my-orders",
            name: "my-projects",
            component: () => import("@/views/app/MyProjects.vue")
          },
          {
            path: "my-saved-items",
            name: "my-saved-items",
            component: () => import("@/views/app/MySavedItems.vue")
          },
          {
            path: "my-cart",
            name: "my-cart",
            component: () => import("@/views/app/MyCart.vue")
          },
          {
            path: "my-profile",
            name: "my-profile",
            component: () => import("@/views/app/MyProfile.vue")
          },
          {
            path: "checkout",
            name: "checkout",
            component: () => import("@/views/app/Checkout.vue")
          },
          {
            path: "receipt/:order",
            name: "receipt",
            component: () => import("@/views/app/Receipt.vue"),
            props: true
          },
          {
            path: ":catchAll(.*)",
            redirect: { name: "my-profile" }
          }
        ]
      }
    ]
  },
  {
    path: "/sample-license",
    name: "sample-license",
    component: () => import("@/views/SampleLicense.vue")
  },
  {
    path: "*",
    redirect: { name: "home" }
  },
  {
    path: "/:catchAll(.*)",
    redirect: { name: "home" }
  }
];

if (process.env.VUE_APP_MAINTENANCE === "true") {
  routes.unshift(
    { path: "*", component: Maintenance },
    { path: "/*", component: Maintenance }
  );
}

let router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: routes
});

router.beforeEach((to, from, next) => {
  if (to.fullPath.substr(0, 2) === "/#") {
    const path = to.fullPath.substr(2);
    next(path);
    return;
  }

  store.state.path = to.name;

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters["auth/isLoggedIn"]) {
      next();
      return;
    }
    next({ name: "home", params: { authError: true } });
  } else {
    next();
  }
});

export default router;
