import axios from "axios";
import moment from "moment";
import DataFormatter from "@/services/data-formatter.js";

const state = {
  list: [],
  reorderIds: JSON.parse(localStorage.getItem("songfile-reorders")) || []
};

const getters = {
  url: (state, getters, rootState, rootGetters) => {
    return `${process.env.VUE_APP_API_URL}/${rootGetters["auth/getUser"].user_id}/orders`;
  },
  getProject: state => order => {
    return state.list.find(i => i.order_number == order);
  },
  projectDetails: () => project => {
    let licenses = [];

    for (let item of project.items) {
      for (let type in item.licenseRequest.numberOfCopies) {
        let license = {
          song_title: item.song_title,
          play_length: item.licenseRequest.playtime,
          performing_artist: item.licenseRequest.artists,
          license_number: Math.ceil(Math.random() * 10000),
          units: item.licenseRequest.numberOfCopies[type],
          license_type: type
        };

        licenses.push(license);
      }
    }

    return licenses;
  },
  getReorderIds: state => state.reorderIds,
  hasReorder: state => !!state.reorderIds.length
};

const actions = {
  async getProjects({ commit, getters }) {
    let items = [];

    await axios.get(getters.url).then(response => {
      const orders = response.data["Songfile_Get_Order"];

      for (const order of orders) {
        items.push({
          braintree_id: order["Braintree ID"],
          order_number: order.order_id,
          license_date: order.order_date,
          total_amt: order.total_amt,
          total_processing_fee: order.total_processing_fee,
          total_void: order["Total voided amount"],
          items: []
        });
      }
    });

    commit("setProjects", items);
  },
  createProject({ getters, rootGetters }, items) {
    return new Promise(resolve => {
      const project = {
        project_name: items[0].licenseRequest.projectName,
        order_number: Math.ceil(Math.random() * 100000),
        artists: [],
        writers: [],
        status: 0,
        units: 0,
        license_date: new Date(),
        items: items,
        total: rootGetters["release/total"]
      };

      const params = {
        userid: rootGetters["auth/getUser"].user_id,
        pnref: localStorage.getItem("pnref"),
        braintree_id: localStorage.getItem("pnref"),
        payment_type: DataFormatter.paymentType()
      };

      axios.post(getters.url, params).then(res => {
        res.data.urls.forEach(url => window.open(url));
        resolve(project);
      });
    });
  },
  orderDetails({ getters }, order) {
    return new Promise(resolve => {
      const items = [];

      axios.get(`${getters.url}/${order.order_number}`).then(response => {
        for (const item of response.data["Songfile_Get_Order"]) {
          items.push(DataFormatter.orderItem(item));
        }

        resolve(items);
      });
    });
  },
  async getPdfLink({ getters }, { order, license }) {
    let url;

    await axios
      .get(`${getters.url}/${order}/download/${license}`)
      .then(response => {
        url = response.data.url;
      });

    return url;
  },
  async reOrder({ commit, dispatch }, order) {
    const getRelease = async function(id) {
      try {
        const release = await dispatch("release/getRelease", id, {
          root: true
        });

        if (!order.braintree_id) {
          console.log("--- LEGACY LICENSE ---");
          release.legacy = true;
        }

        return release;
      } catch (error) {
        console.log("ERROR - REORDER GET RELEASE: ", error);
        throw error;
      }
    };

    const createRelease = async function(params) {
      if (params.album == "") {
        params.album = "-";
      }
      if (params.distribution == "") {
        params.distribution = "United States";
      }

      try {
        return await dispatch("release/createRelease", params, { root: true });
      } catch (error) {
        console.log("ERROR - REORDER CREATE RELEASE: ", error);
        throw error;
      }
    };

    const releaseIds = [...new Set(order.items.map(i => i.releaseId))];
    const songs = order.items.map(i => {
      return {
        licenseRequest: { ...i.licenseRequest },
        song_code: i.songCode,
        releaseId: i.releaseId
      };
    });

    for (const releaseId of releaseIds) {
      const release = await getRelease(releaseId);
      release.release_date = moment(release.release_date, "YYYY-MM-DD").format(
        "MM-DD-YYYY"
      );

      const newRelease = await createRelease(release);

      commit("addReorderReleaseId", newRelease.id);

      const uniqSongs = songs.filter((i, index, self) => {
        return (
          i.releaseId == releaseId &&
          index ===
            self.findIndex(j => {
              return JSON.stringify(j) === JSON.stringify(i);
            })
        );
      });

      for (const song of uniqSongs) {
        await dispatch(
          "release/addItemToRelease",
          { song: song, release: newRelease, reloadCart: false },
          { root: true }
        );
      }
    }

    await dispatch("release/recoverReleases", {}, { root: true });

    return true;
  },
  checkReorderIds({ commit, getters, rootGetters }) {
    const reorders = getters.getReorderIds;
    const releases = rootGetters["release/getReleases"].map(i => i.id);

    for (const reorder of reorders) {
      if (!releases.includes(reorder)) {
        commit("removeReorderId", reorder);
      }
    }
  }
};

const mutations = {
  addReorderReleaseId(state, id) {
    state.reorderIds.push(id);
    localStorage.setItem("songfile-reorders", JSON.stringify(state.reorderIds));
  },
  removeReorderId(state, id) {
    state.reorderIds.splice(state.reorderIds.indexOf(id), 1);
    localStorage.setItem("songfile-reorders", JSON.stringify(state.reorderIds));
  },
  setProjects(state, items) {
    state.list = items;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
