<template>
  <router-view />
</template>

<script>
export default {
  name: "App",
  metaInfo: function() {
    return {
      titleTemplate: "Songfile%s"
    };
  },
  data: function() {
    return {};
  }
};
</script>

<style lang="scss"></style>
