<template>
  <div id="contact-us">
    <div class="contactus">
      <section class="section head">
        <div class="container">
          <h1>Contact Us</h1>
        </div>
      </section>

      <section class="section search-section">
        <div class="container">
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-size-100">
              <div v-if="!contactSent">
                <div class="contactus-faq">
                  <h4 class="section-heading text-left">
                    Which best describes your question?
                  </h4>

                  <md-field class="md-layout">
                    <label for="question" class="md-layout-item md-size-100"
                      >Please select a question:</label
                    >

                    <select
                      v-model="question"
                      name="question"
                      id="question"
                      class="md-layout-item md-size-100"
                    >
                      <option
                        v-for="(q, i) in questions"
                        :key="i"
                        :value="JSON.stringify(q)"
                      >
                        {{ q.question }}
                      </option>
                    </select>
                  </md-field>
                </div>

                <div class="contactus-result-wrap" v-show="question != ''">
                  <h4 class="section-heading text-left">
                    WE MAY BE ABLE TO ANSWER THAT ALREADY!
                  </h4>

                  <p>
                    Please see below from our
                    <router-link :to="{ name: 'faq' }" target="_blank"
                      >Licensing FAQ</router-link
                    >
                  </p>

                  <div class="contactus-result">
                    <div
                      class="question"
                      v-html="selectedQuestion.question"
                    ></div>
                    <div class="answer" v-html="selectedQuestion.answer"></div>
                  </div>

                  <div class="contactus-feedback-wrap">
                    <div class="contactus-feedback">
                      <h4 class="section-heading text-left">
                        DID THAT ANSWER YOUR QUESTION?
                      </h4>

                      <div class="contactus-feedback-btns">
                        <md-button
                          class="md-primary md-raised"
                          @click="(answered = false), (faqAnswer = true)"
                        >
                          Yes
                        </md-button>
                        <md-button
                          class="md-primary md-raised"
                          @click="(answered = false), (faqAnswer = false)"
                        >
                          No
                        </md-button>
                      </div>
                    </div>

                    <div
                      class="contactus-feedback-form-wrap"
                      v-show="!answered"
                    >
                      <div v-show="!contactSent">
                        <p v-show="!faqAnswer">
                          Sorry about that.<br />
                          Please enter your question or additional details
                          below.<br />
                          Thank you!
                        </p>
                      </div>
                      <div v-show="!contactSent">
                        <p v-show="faqAnswer">
                          Great!<br />
                          If you still have a question please enter it below.<br />
                          Thank you!
                        </p>
                      </div>

                      <br />
                      <h4
                        class="section-heading text-left"
                        v-show="!contactSent"
                      >
                        CONTACT US
                      </h4>

                      <form
                        novalidate
                        @submit.prevent="submit"
                        class="contactus-feedback-form"
                        v-show="!contactSent"
                      >
                        <md-field class="md-layout md-gutter">
                          <div
                            class="md-layout-item md-size-30 md-small-size-100"
                          >
                            <label for="name">Name *</label>
                          </div>
                          <div
                            class="md-layout-item md-size-70 md-small-size-100"
                          >
                            <md-input id="name" v-model="contact.name" />
                          </div>
                        </md-field>

                        <md-field class="md-layout md-gutter">
                          <div
                            class="md-layout-item md-size-30 md-small-size-100"
                          >
                            <label for="email">Email *</label>
                          </div>
                          <div
                            class="md-layout-item md-size-70 md-small-size-100"
                          >
                            <md-input id="email" v-model="contact.email" />
                          </div>
                        </md-field>

                        <md-field class="md-layout md-gutter">
                          <div
                            class="md-layout-item md-size-30 md-small-size-100"
                          >
                            <label for="details">Details *</label>
                          </div>
                          <div
                            class="md-layout-item md-size-70 md-small-size-100"
                          >
                            <md-textarea
                              v-model="contact.details"
                            ></md-textarea>
                          </div>
                        </md-field>

                        <vue-recaptcha
                          ref="recaptcha"
                          class="recaptcha"
                          sitekey="6LfglcQaAAAAAMkVirQrPQw8b5nd3RoyI3RUsgtE"
                          @verify="onVerify"
                        ></vue-recaptcha>

                        <md-button
                          type="submit"
                          :disabled="!isValid"
                          class="md-raised md-primary large-width"
                        >
                          Submit
                        </md-button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>

              <div class="contactus-feedback-response" v-else>
                <div v-if="contactSent && !contactError">
                  <h4 class="section-heading text-left">THANK YOU</h4>
                  <p>We’ll get back to you soon.</p>
                </div>

                <div v-if="contactError">
                  <h4 class="section-heading text-left">Sorry</h4>
                  <p>
                    Something went wrong and we couldn't send your message.
                    <br />
                    Please try again later.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import VueRecaptcha from "vue-recaptcha";
import { validEmail } from "@/services/shared.js";

export default {
  name: "ContactUs",
  components: { VueRecaptcha },
  metaInfo: function() {
    return {
      title: " - Contact Us",
      meta: [
        {
          name: "description",
          content: "Which best describes your question?"
        }
      ],
      link: [
        {
          vmid: "canonical",
          rel: "canonical",
          href: this.$store.getters["canonical"]()
        }
      ]
    };
  },
  data: function() {
    return {
      categories: null,
      question: "",
      answered: true,
      faqAnswer: true,
      contact: {
        name: "",
        email: "",
        details: ""
      },
      contactSent: false,
      contactError: false,
      recaptchaToken: null
    };
  },
  computed: {
    questions: function() {
      return this.$store.state.faq.questions;
    },
    selectedQuestion: function() {
      if (this.question != "") {
        return JSON.parse(this.question);
      } else {
        return { question: "", answer: "" };
      }
    },
    isValid: function() {
      if (this.recaptchaToken == null) {
        return false;
      }

      for (const key in this.contact) {
        if (this.contact[key] == "") {
          return false;
        }
      }

      return true;
    }
  },
  methods: {
    onVerify: function(response) {
      this.recaptchaToken = response;
    },
    submit: function() {
      if (!this.isValid) {
        return false;
      }

      if (!validEmail(this.contact.email)) {
        this.$toastr("error", "Invalid email");
        return false;
      }

      const url = process.env.VUE_APP_API_URL.replace(
        "songfile/v1",
        "business-contact/v1/faq"
      );

      const params = {
        origin: "songfile",
        name: this.contact.name,
        email: this.contact.email,
        content: this.contact.details
      };

      axios
        .post(url, params)
        .then(() => {
          this.contactSent = true;
        })
        .catch(() => {
          this.contactSent = true;
          this.contactError = true;
        });
    }
  }
};
</script>

<style lang="scss">
#contact-us {
  .contactus {
    #question {
      border: 1px solid #ccc;
      background-color: #fff;
      padding: 10px;
      border-radius: 3px;
      font-size: 1em;
      cursor: pointer;
    }
    &-result {
      margin: 25px 0;

      .question {
        &:before {
          display: inline-block;
          font-weight: bold;
          margin-right: 10px;
          content: "Q: ";
        }
      }

      .answer {
        p {
          font-size: 14px;
          color: #000;
          font-weight: 300;
          margin: 0 0 10px;

          &:first-child:before {
            display: inline-block;
            font-weight: bold;
            margin-right: 10px;
            content: "A: ";
          }
        }
      }

      &-wrap {
        margin: 40px 0;
      }
    }

    &-feedback {
      position: relative;

      &-wrap {
        position: relative;
        border-top: 1px solid $color-grey-6;
        margin-top: 35px;
        padding-top: 35px;
      }

      &-form {
        width: 100%;
        max-width: 500px;

        input,
        textarea {
          width: 100%;
        }

        .md-field {
          align-items: flex-start;
        }

        .md-button {
          display: block;
          margin: 0 auto;
        }

        textarea {
          border: 1px solid #ccc;
        }

        .recaptcha {
          display: flex;
          justify-content: flex-end;
          margin: 0 40px 20px 0;

          div,
          iframe {
            height: 78px;
          }
        }
      }
    }
  }
}
</style>
