<template>
  <md-app>
    <md-app-content>
      <router-view :relogin="relogin" />
    </md-app-content>
  </md-app>
</template>

<script>
import axios from "axios";

export default {
  name: "App",
  data: function() {
    return {
      relogin: false
    };
  },
  computed: {
    releases: function() {
      return this.$store.state.release.releases;
    }
  },
  mounted: function() {
    // console.log("TEST");
    document.querySelector(".md-app-content").classList.remove("md-scrollbar");
  },
  beforeCreate: function() {
    axios.interceptors.response.use(
      response => response,
      error => {
        if (
          error.response &&
          error.response.data &&
          !error.response.data.reset &&
          error.response.status == 401
        ) {
          this.$store.dispatch("auth/logout");
          this.$toastr("error", "You need to be logged in to proceed.");
          this.relogin = true;
        }
        return Promise.reject(error);
      }
    );
  },
  created: function() {
    this.$store.dispatch("auth/tokenHeader");

    if (this.$store.state.path != "my-cart") {
      this.$store.dispatch("release/recoverReleases");
    }
  }
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600|Material+Icons&subset=cyrillic");

@import "~vue-material/dist/theme/engine"; // Import the theme engine
@include md-register-theme(
  "default",
  (
    primary: #ea2b24,
    accent: md-get-palette-color(black, 500),
    secondary: white
  )
);
@import "~vue-material/dist/theme/all"; // Apply the theme

@font-face {
  font-family: "Gilroy-Regular";
  src: url("assets/fonts/hinted-Gilroy-Regular.eot");
  src: url("assets/fonts/hinted-Gilroy-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("assets/fonts/hinted-Gilroy-Regular.woff2") format("woff2"),
    url("assets/fonts/hinted-Gilroy-Regular.woff") format("woff"),
    url("assets/fonts/hinted-Gilroy-Regular.ttf") format("truetype"),
    url("assets/fonts/hinted-Gilroy-Regular.svg#Gilroy-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy-Semibold";
  src: url("assets/fonts/hinted-Gilroy-Semibold.eot");
  src: url("assets/fonts/hinted-Gilroy-Semibold.eot?#iefix")
      format("embedded-opentype"),
    url("assets/fonts/hinted-Gilroy-Semibold.woff2") format("woff2"),
    url("assets/fonts/hinted-Gilroy-Semibold.woff") format("woff"),
    url("assets/fonts/hinted-Gilroy-Semibold.ttf") format("truetype"),
    url("assets/fonts/hinted-Gilroy-Semibold.svg#Gilroy-Semibold") format("svg");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy-Extrabold";
  src: url("assets/fonts/hinted-Gilroy-Extrabold.eot");
  src: url("assets/fonts/hinted-Gilroy-Extrabold.eot?#iefix")
      format("embedded-opentype"),
    url("assets/fonts/hinted-Gilroy-Extrabold.woff2") format("woff2"),
    url("assets/fonts/hinted-Gilroy-Extrabold.woff") format("woff"),
    url("assets/fonts/hinted-Gilroy-Extrabold.ttf") format("truetype"),
    url("assets/fonts/hinted-Gilroy-Extrabold.svg#Gilroy-Extrabold")
      format("svg");
  font-weight: 800;
  font-style: normal;
}

// --------------------------
// -------- CUSTOM ----------
// --------------------------

.toast-container > div {
  opacity: 1 !important;
}

.md-datepicker-dialog {
  min-height: 286px;
  // max-height: 286px;
  z-index: 99999 !important;
}

.md-small-hide {
  @media (max-width: 960px) {
    display: none !important;
  }
}

html.has-overlay {
  overflow: hidden;

  body .md-app #main {
    > *:not(.md-content),
    > .md-content > div > *:not(.overlay) {
      filter: blur(5px);
    }
    > .md-content > div {
    }
  }
}
.md-app {
  min-height: 100vh;

  .md-app-container {
    transform: none;
    overflow-x: hidden;
  }

  .md-app-content {
    padding: 0;
    height: auto;

    .head {
      background: $color-teal url("assets/img/songfile-head.png") no-repeat 50%
        100%;
      background-size: cover;
      min-height: 120px;
      color: #fff !important;
      display: flex;
      align-items: center;

      h1 {
        color: #fff;
        display: block;
        line-height: 1em;
      }
      span {
        font-size: 16px;
        display: block;
        text-transform: none;
      }
    }

    .no-nav {
      position: relative;
      min-height: calc(100vh);
      border-width: 30px 0;
      border-style: solid;
      border-color: $primary;
      padding: 0 20px;

      h1 {
        color: $primary;
        font-size: 3em;
        text-transform: uppercase;
        margin: 0.6em 0;
      }
      h1 + sub {
        font-size: 1em;
      }
    }
  }
}

.upc-isrc-warn {
  position: absolute;
  font-size: 12px;
}

.buttons {
  margin: 30px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > * {
    margin: 5px 0;
  }

  @media all and (min-width: 576px) {
    flex-direction: row;

    > * {
      margin: 0 5px;
    }
  }
}

.md-theme-default {
  a:not(.md-button) {
    color: $blue;
  }
  .md-field {
    min-height: auto;
    padding: 0;
    align-items: center;

    &:before,
    &:after {
      display: none;
    }

    label {
      color: #000;
      font-size: 1em;
      font-family: $font-Gilroy-regular;
      font-weight: $fw-regular;
      left: initial;
      line-height: 1.4;
      position: static;
      top: initial;
    }
    .md-input {
      background-color: #fff;
      border: 1px solid #ccc;
      flex: 0 0 auto;
      font-size: 1em;
      height: auto;
      line-height: 1.4;
      padding: 0.6em 1em;
    }

    .md-select {
      border: 1px solid #ccc;

      .md-input {
        border: 0;
      }
    }

    &.md-invalid {
      .md-error {
        bottom: -18px;
        color: red;
        left: initial;
        right: 0;
      }
      .md-input,
      .md-select {
        border-color: red !important;
      }
    }

    &.md-has-value,
    &.md-focused {
      label {
        font-size: 1em;
        top: initial;
      }
      .md-input {
        font-size: 1em;
      }
    }
    &.md-has-password {
      .md-button {
        top: 3px;
        bottom: initial;
        right: 3px;
        border: 0;
      }
    }
  }

  &.md-button:not([test]) {
    background-color: transparent;
    border-radius: 100px;
    border: 2px solid #fff;
    color: #333;
    font-family: $font-Gilroy-extrabold;
    font-size: 14px;
    text-transform: initial;
    padding: 0 10px;

    .md-button-content {
      display: flex;
      align-items: center;

      .md-icon {
        font-weight: normal;
        margin: 0 5px 0 -5px;
      }
    }

    &:hover {
      color: #fff;
      border-color: transparent;

      .md-button-content {
        color: #fff;
      }
    }

    &:active:before,
    &:hover:before {
      opacity: 1;
      background: $color-teal-dark;
    }

    &.md-raised {
      color: #333;

      &.md-primary {
        background-color: $color-teal;
        border: 0;
        box-shadow: none;
        color: #fff;
      }

      &.md-green {
        background-color: $color-green;
        border: 0;
        box-shadow: none;
        color: #fff;
      }
      &.md-red {
        background-color: $color-red;
        border: 0;
        box-shadow: none;
        color: #fff;
      }
      &[disabled] {
        background-color: rgba(0, 0, 0, 0.12);
      }
    }

    &.md-icon-button {
      padding: 0;
      border: 0;

      .md-button-content {
        justify-content: center;

        .md-icon {
          margin: 0;
        }
      }

      &.md-raised {
        .md-button-content {
          .md-icon {
            color: inherit;
          }
        }
      }
    }

    &.large-width {
      width: 220px;
    }
  }
}
.md-dialog .md-dialog-container {
  border-radius: 5px;
  // max-width: 90%;

  &.edit-release {
    width: 90%;
  }

  .close-dialog {
    position: absolute;
    top: 5px;
    right: 15px;
    font-weight: bold;
    cursor: pointer;
    z-index: 2;

    &:hover {
      text-decoration: none;
    }
  }

  .md-dialog-title {
    background-color: $green;
    color: #fff;
    font-size: 1.3em;
    font-weight: bold;
    margin: 0;
    padding: 1.5em 40px;

    .close {
      position: absolute;
      top: 25px;
      right: 25px;
      font-weight: bold;
      cursor: pointer;
      z-index: 2;
      transform: scale(0.75);
    }

    .add-button {
      margin: 0px 0 0 20px;
      height: 24px;
      font-size: 14px;
      padding: 0 2px;
      border: 0;

      .md-icon {
        width: 18px;
        height: 18px;
        min-width: 18px;
        min-height: 18px;
        font-size: 18px !important;
      }

      &:hover {
        .md-icon {
          color: #fff;
        }
      }
    }

    @media all and (min-width: 576px) {
      font-size: 2em;
      padding: 1em 40px;

      .close {
        transform: scale(1);
      }
    }
  }
  .md-dialog-content {
    padding: 0;

    @media all and (min-width: 576px) {
      padding: 0 24px 24px;
    }
  }
}
</style>
