<template>
  <div id="checkout">
    <section class="section head">
      <div class="container">
        <h1>
          CHECKOUT
        </h1>
      </div>
    </section>

    <section class="section">
      <div class="container">
        <div class="md-layout accordion-list">
          <div
            class="md-layout-item md-size-100 item licensing-terms"
            :class="{ open: step == 1 }"
          >
            <div class="item-header">
              <div class="number" :class="{ done: step > 1 }">
                <span>1</span>
                <md-icon>check</md-icon>
              </div>

              <h4>AGREE TO LICENSING TERMS</h4>
            </div>

            <div class="item-content">
              <p>
                Please review the sample Songfile license below. The sample
                license sets forth the licensing terms that will govern the
                license request by you. In order to complete your licensing
                request, you must agree to the terms by clicking "ACCEPT" below
                the sample license. The license will not be valid until you have
                provided all required information and payment in full has been
                made. The terms of the license will be governed by the final
                form available to you upon completion of the transaction.
              </p>

              <div class="terms" id="licensing-terms">
                <LicenseTerms class="no-pdf" />
              </div>

              <div class="item-footer md-layout">
                <div class="md-layout-item md-size-25 md-small-size-100">
                  <md-button
                    @click="step = 2"
                    :disabled="!scrolledTerms"
                    class="md-primary md-raised large-width"
                  >
                    Accept
                  </md-button>
                </div>

                <span
                  class="md-layout-item md-size-75 md-small-size-100"
                  style="text-align: left;"
                >
                  These terms will be available to download once your license
                  has cleared.
                </span>
              </div>
            </div>
          </div>

          <div
            class="md-layout-item md-size-100 item licensee-info"
            :class="{ open: step == 2 }"
          >
            <div class="item-header">
              <div class="number" :class="{ done: step > 2 }">
                <span>2</span>
                <md-icon>check</md-icon>
              </div>

              <h4>REVIEW LICENSEE INFORMATION</h4>
            </div>

            <div class="item-content">
              <p>
                Please edit the information as necessary. The
                <strong>Licensee Name</strong> should be the label or
                organization (or individual) who will be distributing the
                recordings.
              </p>

              <div class="table md-layout">
                <div
                  class="column md-layout-item md-size-50 md-small-size-100 md-layout"
                >
                  <div class="md-layout-item md-size-40 label">
                    Licensee Name:
                  </div>
                  <div class="md-layout-item md-size-60 name">
                    {{ user.licensee_name }}
                  </div>

                  <div class="md-layout-item md-size-40 label">
                    Street Address 1:
                  </div>
                  <div class="md-layout-item md-size-60 name">
                    {{ user.street_address }}
                  </div>

                  <div class="md-layout-item md-size-40 label">
                    Street Address 2:
                  </div>
                  <div class="md-layout-item md-size-60 name">
                    {{ user.street_address_two }}
                  </div>

                  <div class="md-layout-item md-size-40 label">
                    Phone Number:
                  </div>
                  <div class="md-layout-item md-size-60 name">
                    {{ user.phone_number }}
                  </div>
                </div>

                <div
                  class="column md-layout-item md-size-50 md-small-size-100 md-layout"
                >
                  <div class="md-layout-item md-size-40 label">Country:</div>
                  <div class="md-layout-item md-size-60 name">
                    {{ user.country }}
                  </div>

                  <div class="md-layout-item md-size-40 label">City:</div>
                  <div class="md-layout-item md-size-60 name">
                    {{ user.city }}
                  </div>

                  <div class="md-layout-item md-size-40 label">
                    State/Province:
                  </div>
                  <div class="md-layout-item md-size-60 name">
                    {{ user.state }}
                  </div>

                  <div class="md-layout-item md-size-40 label">
                    Zip/Postal Code:
                  </div>
                  <div class="md-layout-item md-size-60 name">
                    {{ user.zip_code }}
                  </div>
                </div>
              </div>

              <div class="item-footer">
                <md-button
                  class="md-raised md-primary"
                  @click="showAccountForm = true"
                  >Edit</md-button
                >
                <md-button class="md-raised md-primary" @click="step = 3">
                  Continue
                </md-button>
              </div>
            </div>
          </div>

          <div
            class="md-layout-item md-size-100 item review-items"
            :class="{ open: step == 3 }"
          >
            <div class="item-header">
              <div class="number" :class="{ done: step > 3 }">
                <span>3</span>
                <md-icon>check</md-icon>
              </div>

              <h4>REVIEW YOUR SELECTIONS</h4>
            </div>

            <div class="item-content">
              <md-table v-model="cart" class="cart-table" v-if="!isMobile">
                <md-table-row slot="md-table-row" slot-scope="{ item }">
                  <md-table-cell md-label="Release" md-sort-by="release">
                    {{ item.release }}
                  </md-table-cell>

                  <md-table-cell md-label="Song Title">
                    {{ item.songTitle }}
                  </md-table-cell>

                  <md-table-cell md-label="Units" class="units">
                    {{ item.units }}
                  </md-table-cell>

                  <md-table-cell md-label="Config" class="config">
                    {{ item.config.substring(0, 4) }}
                  </md-table-cell>

                  <md-table-cell md-label="Song Code">
                    {{ item.songCode }}
                  </md-table-cell>

                  <md-table-cell md-label="Artist">
                    {{ item.licenseRequest.artists }}
                  </md-table-cell>

                  <md-table-cell md-label="Playtime">
                    {{ item.licenseRequest.minutes | numeral("00") }}:{{
                      item.licenseRequest.seconds | numeral("00")
                    }}
                  </md-table-cell>

                  <md-table-cell md-label="Royalty Rate">
                    {{ item.royaltyFee | numeral("$0,0.0000") }}
                  </md-table-cell>

                  <md-table-cell md-label="Royalty Amount">
                    <strong>{{
                      item.royaltyAmount | numeral("$0,0.00")
                    }}</strong>
                  </md-table-cell>

                  <md-table-cell md-label="Processing Fee">
                    <strong>{{
                      item.processingFee | numeral("$0,0.00")
                    }}</strong>
                  </md-table-cell>
                </md-table-row>
              </md-table>

              <div v-else class="mobile-cart">
                <div
                  class="md-layout"
                  v-for="(item, index) in cart"
                  :key="index"
                >
                  <div class="md-layout-item md-size-35 label">
                    Release
                  </div>
                  <div class="md-layout-item md-size-65">
                    {{ item.release }}
                  </div>

                  <div class="md-layout-item md-size-35 label">
                    Song Title
                  </div>
                  <div class="md-layout-item md-size-65">
                    {{ item.songTitle }}
                  </div>

                  <div class="md-layout-item md-size-35 label">
                    Units
                  </div>
                  <div class="md-layout-item md-size-65">
                    {{ item.units }}
                  </div>

                  <div class="md-layout-item md-size-35 label">
                    Config
                  </div>
                  <div class="md-layout-item md-size-65">
                    {{ item.config.substring(0, 4) }}
                  </div>

                  <div class="md-layout-item md-size-35 label">
                    Song Code
                  </div>
                  <div class="md-layout-item md-size-65">
                    {{ item.songCode }}
                  </div>

                  <div class="md-layout-item md-size-35 label">
                    Artist
                  </div>
                  <div class="md-layout-item md-size-65">
                    {{ item.licenseRequest.artists }}
                  </div>

                  <div class="md-layout-item md-size-35 label">
                    Playtime
                  </div>
                  <div class="md-layout-item md-size-65">
                    {{ item.licenseRequest.minutes | numeral("00") }}:{{
                      item.licenseRequest.seconds | numeral("00")
                    }}
                  </div>

                  <div class="md-layout-item md-size-35 label">
                    Royalty Rate
                  </div>
                  <div class="md-layout-item md-size-65">
                    {{ item.royaltyFee | numeral("$0,0.0000") }}
                  </div>

                  <div class="md-layout-item md-size-35 label">
                    Royalty Amount
                  </div>
                  <div class="md-layout-item md-size-65">
                    <strong>{{
                      item.royaltyAmount | numeral("$0,0.00")
                    }}</strong>
                  </div>

                  <div class="md-layout-item md-size-35 label">
                    Processing Fee
                  </div>
                  <div class="md-layout-item md-size-65">
                    <strong>{{
                      item.processingFee | numeral("$0,0.00")
                    }}</strong>
                  </div>
                </div>
              </div>

              <div class="table-footer">
                <div></div>
                <div class="total">
                  <div>
                    <router-link
                      target="_blank"
                      :to="{ name: 'faq' }"
                      class="help"
                    >
                      <md-icon>help</md-icon>
                    </router-link>
                    <div class="name">Royalty Amount Subtotal</div>
                    <div class="value">
                      {{ royaltyAmountSubtotal() | numeral("$0,0.00") }}
                    </div>
                  </div>
                  <div>
                    <router-link
                      target="_blank"
                      :to="{ name: 'faq' }"
                      class="help"
                    >
                      <md-icon>help</md-icon>
                    </router-link>
                    <div class="name">Processing Fees</div>
                    <div class="value">
                      {{ processingFeesTotal() | numeral("$0,0.00") }}
                    </div>
                  </div>
                  <div>
                    <div class="name">Total</div>
                    <div class="value">
                      {{ total() | numeral("$0,0.00") }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="item-footer">
                <md-button
                  class="md-raised md-primary"
                  :to="{ name: 'my-cart' }"
                >
                  Edit
                </md-button>
                <md-button class="md-raised md-primary" @click="getToken()">
                  Continue
                </md-button>
              </div>
            </div>
          </div>

          <div
            class="md-layout-item md-size-100 item license-info"
            :class="{ open: step == 4 }"
          >
            <div class="item-header">
              <div class="number" :class="{ done: step > 4 }">
                <span>4</span>
                <md-icon>check</md-icon>
              </div>

              <h4>PAYMENT</h4>
            </div>

            <div class="item-content">
              <p>
                Your total due is:
                <strong>{{ total() | numeral("$0,0.00") }}</strong>
              </p>

              <div id="dropin-wrapper">
                <div id="checkout-message"></div>
                <div id="dropin-container"></div>

                <md-button
                  class="md-raised md-primary"
                  id="submit-button"
                  :disabled="!loaded"
                >
                  <span v-show="!loaded">Loading</span>
                  <span v-show="loaded">Submit Payment and Download</span>
                </md-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <md-dialog :md-active.sync="showAccountForm" @md-closed="closeDialog">
      <md-dialog-title>
        <span>YOUR PROFILE</span>

        <md-button
          class="md-raised md-dense md-icon-button close"
          @click="showAccountForm = false"
        >
          <md-icon>clear</md-icon>
        </md-button>
      </md-dialog-title>

      <md-dialog-content>
        <AccountForm @closeDialog="showAccountForm = false" checkout="true" />
      </md-dialog-content>
    </md-dialog>

    <div class="payment-overlay overlay" v-show="loadingPayment">
      Loading...
      <br />
      <br />
      <md-progress-spinner md-mode="indeterminate"></md-progress-spinner>
    </div>
  </div>
</template>

<script>
import AccountForm from "@/components/AccountForm.vue";
import LicenseTerms from "@/components/LicenseTerms.vue";
import { mapGetters, mapState } from "vuex";
import axios from "axios";

export default {
  name: "Checkout",
  components: {
    AccountForm,
    LicenseTerms
  },
  metaInfo: function() {
    return {
      title: " - Checkout",
      meta: [
        {
          name: "description",
          content: "Check your information and proceed to checkout."
        }
      ],
      link: [
        {
          vmid: "canonical",
          rel: "canonical",
          href: this.$store.getters["canonical"]()
        }
      ]
    };
  },
  data: function() {
    return {
      showAccountForm: false,
      step: 1,
      loaded: false,
      loadingPayment: false,
      scrolledTerms: false
    };
  },
  computed: {
    ...mapState({
      status: state => state.release.status
    }),
    cart: function() {
      return this.$store.getters["release/getAllCartItems"];
    },
    user: function() {
      return this.$store.state.auth.user;
    },
    isMobile: function() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
    },
    isCartEmpty: function() {
      return this.$store.getters["release/getAllCartItems"].length == 0;
    }
  },
  watch: {
    status: function(newValue) {
      if (newValue == "idle" && this.isCartEmpty) {
        this.$toastr("error", "Your cart is empty.");
        this.$router.push({ name: "new-release" });
      }
    }
  },
  methods: {
    ...mapGetters("release", [
      "royaltyAmountSubtotal",
      "processingFeesTotal",
      "total"
    ]),
    closeDialog: function() {
      this.showAccountForm = false;
    },
    addLoading: function() {
      this.loadingPayment = true;
      document.getElementsByTagName("html")[0].classList.add("has-overlay");
    },
    removeLoading: function() {
      this.loadingPayment = false;
      document.getElementsByTagName("html")[0].classList.remove("has-overlay");
    },
    getToken: function() {
      this.step = 4;

      axios.get(`${process.env.VUE_APP_API_URL}/cart/token`).then(response => {
        this.pay(response.data.client_token);
      });
    },
    pay: function(client_token) {
      const $this = this;
      const button = document.querySelector("#submit-button");
      const dropin = require("braintree-web-drop-in");
      const myContainer = document.getElementById("dropin-container");
      myContainer.innerHTML = "";

      dropin.create(
        {
          authorization: client_token,
          container: "#dropin-container"
        },
        (createErr, instance) => {
          $this.loaded = true;

          button.addEventListener("click", () => {
            $this.addLoading();

            instance.requestPaymentMethod(
              (requestPaymentMethodErr, payload) => {
                if (requestPaymentMethodErr) {
                  $this.removeLoading();
                  return false;
                }

                const params = {
                  payment_method_nonce: payload.nonce,
                  fee: $this.total().toFixed(2),
                  email: $this.user.email
                };

                axios
                  .post(
                    `${process.env.VUE_APP_API_URL}/cart/transaction`,
                    params
                  )
                  .then(response => {
                    if (response.data.errors) {
                      $this.removeLoading();
                      for (const error of response.data.errors) {
                        $this.$toastr("error", error.message);
                      }
                      return false;
                    }

                    localStorage.setItem("pnref", response.data.transaction.id);
                    localStorage.setItem(
                      "payment_type",
                      response.data.transaction.credit_card.card_type
                    );

                    if (!response.data.message) {
                      $this.$store.dispatch("release/checkout").then(
                        () => {
                          $this.removeLoading();
                          $this.$router.push({
                            name: "receipt",
                            params: { order: response.data.transaction.id }
                          });
                        },
                        error => {
                          $this.removeLoading();
                          $this.$toastr("error", error);
                        }
                      );
                    } else {
                      $this.removeLoading();
                      $this.$toastr(
                        "error",
                        "We could not complete your transaction please try again"
                      );
                    }
                  });
              }
            );
          });
        }
      );
    }
  },
  mounted: function() {
    const terms = document.getElementById("licensing-terms");
    terms.addEventListener("scroll", () => {
      if (
        Math.ceil(terms.scrollHeight - terms.scrollTop) <=
        terms.clientHeight + 100
      ) {
        this.scrolledTerms = true;
      }
    });
  }
};
</script>

<style lang="scss">
#checkout {
  .payment-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    flex-direction: column;
    color: #fff;
    font-size: 2em;
  }
  .accordion-list {
    .item {
      &.licensing-terms {
        .item-footer {
          .md-button {
            display: block;
            margin: 0 auto 10px;
          }
          span {
            text-align: center;
          }
        }
      }
      &.licensee-info,
      &.review-items {
        .item-footer {
          justify-content: center;

          @media all and (min-width: 990px) {
            justify-content: flex-start;
          }
        }
      }
      &.licensee-info {
        .table {
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;

          .column {
            display: flex;
            flex-wrap: wrap;
            font-size: 13px;

            > div {
              padding: 7px 0;
            }

            .label {
              font-weight: bold;
              font-family: $font-Gilroy-extrabold;
            }

            @media all and (min-width: 990px) {
              font-size: 16px;

              > div {
                padding: 10px 0;
              }
            }
          }
        }
      }
      &.review-items {
        .mobile-cart {
          .md-layout {
            padding-bottom: 10px;
            margin-bottom: 10px;
            border-bottom: 1px solid #efefef;

            &:last-child {
              border: 0;
            }
          }
          .md-layout-item {
            padding: 7.5px 0;

            &.label {
              font-family: $font-Gilroy-extrabold;
            }
          }
        }
      }
    }
  }
}
</style>
