<template>
  <div id="account-recovery">
    <section class="section head">
      <div class="container">
        <h1 v-if="!changePwd">Account Recovery</h1>
        <h1 v-if="changePwd">Change Password</h1>
        <span v-if="changePwd">
          Enter the email address associated with your account to change your
          password
        </span>
      </div>
    </section>

    <section class="section body">
      <form novalidate="true" @submit.prevent="recovery" v-if="!emailSent">
        <md-field>
          <label for="email">Email Address:</label>
          <md-input type="email" v-model="email" />
        </md-field>

        <md-button type="submit" class="md-raised md-primary large-width">
          Submit
        </md-button>
      </form>
    </section>
  </div>
</template>
<script>
import { validEmail } from "@/services/shared.js";

export default {
  name: "AccountRecovery",
  metaInfo: function() {
    return {
      title: " - Account Recovery",
      meta: [
        {
          name: "description",
          content: "Enter your email address to recover your account."
        }
      ],
      link: [
        {
          vmid: "canonical",
          rel: "canonical",
          href: this.$store.getters["canonical"]()
        }
      ]
    };
  },
  data: function() {
    return {
      email: null,
      emailSent: false,
      changePwd: false
    };
  },
  methods: {
    recovery: async function() {
      if (!validEmail(this.email)) {
        this.$toastr("error", "Invalid email");
      } else {
        try {
          await this.$store.dispatch("auth/accountRecovery", this.email);
          this.$toastr(
            "info",
            "We sent an email with instructions to recover your account."
          );
          this.$router.push({ name: "account-change" });
        } catch (error) {
          this.$toastr(
            "error",
            "Something went wrong. Please contact our support."
          );
        }
      }
    }
  },
  created: function() {
    if (this.$route.name == "change-password") {
      this.changePwd = true;
    }
  }
};
</script>
<style lang="scss">
#account-recovery {
  section.body {
    > * {
      width: 400px;
      display: block;
      margin: 0 auto;
      text-align: center;
    }

    form {
      .md-field {
        flex-direction: column;
        font-size: 1.2em;

        .md-input {
          width: 100%;
        }
      }
    }

    .sent {
      font-size: 1.4em;
      font-weight: bold;
      width: auto;
    }
  }
}
</style>
