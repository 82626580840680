import moment from "moment";
import States from "@/services/states.js";
import Countries from "@/services/countries.js";

export default {
  postCartItem(item, release) {
    return {
      user_id: 1,
      isrc_code: item.licenseRequest.isrc,
      label_name: item.licenseRequest.label,
      song_code: item.song_code,
      play_time_min: item.licenseRequest.minutes,
      play_time_sec: item.licenseRequest.seconds,
      performing_artist: item.licenseRequest.artists,
      cart_flag: "C",
      release_date: release.release_date,
      release_name: release.album,
      release_id: release.id
    };
  },
  postReOrderItem(item) {
    return {
      user_id: 1,
      isrc_code: item.licenseRequest.isrc,
      song_code: item.song_code,
      play_time_min: item.licenseRequest.minutes,
      play_time_sec: item.licenseRequest.seconds,
      performing_artist: item.licenseRequest.artists,
      cart_flag: "C",
      release_id: item.releaseId
    };
  },
  putCartItem(item, flag) {
    return {
      release_name: item.release,
      performing_artist: item.licenseRequest.artists,
      license_type_code: item.config,
      quantity: item.units,
      isrc_code: item.licenseRequest.isrc,
      label_name: item.licenseRequest.label,
      play_time_min: item.licenseRequest.minutes,
      play_time_sec: item.licenseRequest.seconds,
      release_date: item.release_date,
      cart_flag: flag == "cart" ? "C" : "S",
      cart_id: item.cartId,
      release_id: item.releaseId
    };
  },
  paymentType() {
    let payment_type = "";
    const payment = localStorage.getItem("payment_type");
    if (payment == "Visa") {
      payment_type = "00";
    } else if (payment == "MasterCard") {
      payment_type = "01";
    } else if (payment == "American Express") {
      payment_type = "02";
    } else {
      payment_type = "1";
    }
    return payment_type;
  },
  releaseCreate(release) {
    const fixOldNames = type => {
      switch (type) {
        case "interactive streaming":
          return "streaming";
        default:
          return type;
      }
    };

    const fixFormat = type => {
      switch (type) {
        case "pdd":
          return "PDD";
        case "cd":
          return "CD";
        case "vinyl":
          return "Vinyl";
        case "cassette":
          return "Cassette";
        case "ringtone":
          return "Ringtone";
        default:
          return type;
      }
    };

    const release_config = [];
    for (const type in release.copies) {
      for (const subType in release.copies[type]) {
        let recording_type = subType == "Download" ? "PDD" : subType;
        let license_type = fixOldNames(type);

        if (release.legacy) {
          [license_type, recording_type] = [recording_type, license_type];
        }

        release_config.push({
          license_type: license_type.toLowerCase(),
          recording_type: fixFormat(recording_type),
          quantity: release.copies[type][subType]
        });
      }
    }
    const new_release = {
      release_name: release.album,
      release_date: release.release_date,
      distributed_region: release.distribution,
      release_detail: { release_config: release_config }
    };
    if (release.upc !== "") {
      new_release.upc = release.upc;
    }
    if (release.record_number !== "") {
      new_release.catalog_number = release.record_number;
    }
    return new_release;
  },
  userCreate(userSignup) {
    const new_user = {
      zip: userSignup.zip_code,
      street_address_1: userSignup.street_address,
      street_address_2: userSignup.street_address_two,
      city: userSignup.city,
      state: userSignup.state,
      phone_number: userSignup.phone_number,
      referred: userSignup.referred,
      email_address: userSignup.email,
      first_name: userSignup.first_name,
      last_name: userSignup.last_name,
      country: userSignup.country,
      company: userSignup.company,
      password: userSignup.password,
      username: userSignup.email,
      licensee_name: userSignup.licensee_name
    };

    Object.keys(new_user).forEach(key =>
      new_user[key] === undefined ||
      new_user[key] === "" ||
      new_user[key] === null
        ? delete new_user[key]
        : ""
    );

    return new_user;
  },
  userUpdate(user) {
    return {
      city: user.city,
      company: user.company,
      country: user.country,
      email: user.email_address,
      first_name: user.first_name,
      last_name: user.last_name,
      phone_number: user.phone_number,
      referred: user.referred,
      state: user.state,
      street_address: user.street_address_1,
      street_address_two: user.street_address_2,
      user_id: user.user_id,
      zip_code: user.postal_code
    };
  },
  userLogin(user) {
    let countries = Countries;
    let states = States;

    const formatCountry = country => {
      if (!country) {
        country = "United States of America";
      }
      return countries.find(i => {
        return i["country name"] == country;
      })["country code"];
    };

    const formatState = state => {
      if (!state) {
        return "";
      }

      return states.find(i => {
        return i["state_name"] == state;
      })["state code"];
    };

    const referredCodes = {
      "1": "search",
      "2": "ad",
      "3": "article",
      "4": "reference",
      "5": "event",
      "6": "marketing",
      "7": "facebook",
      "8": "twitter",
      "9": "linkedin",
      "10": "other"
    };

    return new Promise(resolve => {
      resolve({
        licensee_name: user.licensee_name,
        city: user.city,
        company: user.company,
        country: formatCountry(user.country),
        email: user.email_address,
        first_name: user.first_name,
        last_name: user.last_name,
        phone_number: user.phone_number,
        referred: referredCodes[user.referred],
        state: formatState(user.state),
        street_address: user.street_address_1,
        street_address_two: user.street_address_2,
        user_id: user.user_id,
        zip_code: user.postal_code
      });
    });
  },
  async FAKE_USER(userId) {
    return {
      licensee_name: "FAKE LICENSEE",
      city: "FAKE CITY",
      company: "FAKE COMPANY",
      country: "USA",
      email: "fake_email@example.com",
      first_name: "FAKE",
      last_name: "USER",
      phone_number: "55555555",
      referred: "1",
      state: "CA",
      street_address: "FAKE STREET 1",
      street_address_two: "FAKE STREET 2",
      user_id: userId,
      zip_code: "12345"
    };
  },
  buildRelease(item, id) {
    const releaseDate = moment(item["Release Date"], "DD-MMM-YY");

    return {
      id: id,
      album: item["Release"],
      distribution: "United States",
      selected_types: [],
      release_date: releaseDate.format("MM-DD-YYYY"),
      copies: {},
      numberOfCopies: {},
      units: item["Units"],
      items: []
    };
  },
  getRelease(release) {
    const releaseDate = moment(release["release_date"], "YYYY-MM-DD");
    const copies = {};
    let units = 0;
    const numberOfCopies = {};
    for (const item of release.license_configuration) {
      const type = item.license_type.toLowerCase();
      if (!copies[type]) {
        copies[type] = {};
        numberOfCopies[type] = 0;
      }
      let recording_type = "";

      if (item.recording_type == "PDD") {
        recording_type = "Download";
      } else {
        recording_type = item.recording_type;
      }

      copies[type][recording_type] = item.quantity;
      units += parseInt(item.quantity);
      numberOfCopies[type] += parseInt(item.quantity);
    }

    return {
      id: release.release_id,
      album: release.release_name,
      distribution: /USA|United States/gi.test(release.distributed_region)
        ? "United States"
        : "Other countries",
      selected_types: Object.keys(copies),
      release_date: releaseDate.format("YYYY-MM-DD"),
      copies: copies,
      upc: release["UPC"],
      record_number: release["Record number"],
      numberOfCopies: numberOfCopies,
      units: units,
      items: []
    };
  },
  buildSong(release, item, loop) {
    const playtime = item["Playtime"].split(":");
    release.id = item["Release_id"];

    return {
      cartId: item["Cart ID"],
      songCode: item["HFA Song Code"] || item["Song Code"],
      songTitle: item["Song Title"],
      originalId: item["HFA Song Code"] || item["Song Code"],
      id: `${item["Cart ID"]}.${item["Recording Type"]}`,
      release: item["Release"],
      releaseId: release.id,
      releaseDate: release.release_date,
      units: item["Units"],
      config: item["Config"],
      recordingType: item["Recording Type"],
      flag: loop == "Songfile_Get_Cart_Values" ? "cart" : "saved",
      licenseRequest: {
        artists: item["Artist"],
        minutes: playtime[0],
        seconds: playtime[1],
        isrc: item["ISRC Code"] || "",
        label: item["label_name"] || ""
      },
      royaltyAmount: item["Royalty Amount"],
      royaltyFee: item["Royalty Rate"],
      processingFee: item["Processing fee"]
    };
  },
  orderItem(item) {
    return {
      release: item["Release Name"],
      songTitle: item["Song Title"],
      units: item["Units"],
      config: item["Type of License"],
      songCode: item["Song Code"],
      releaseId: item["Release ID"],
      licenseRequest: {
        artists: item["Performing Artist"],
        minutes: item["Play Minutes"],
        seconds: item["Play Seconds"],
        isrc: item["ISRC"]
      },
      royaltyFee: item["Royalty Rate"],
      royaltyAmount: item["Royalty Amount"],
      processingFee: item["Processing Fee"],
      licenseNumber: item["License Number"],
      voidedAmount: item["Voided Amount"]
    };
  }
};
