import axios from "axios";
import DataFormatter from "@/services/data-formatter.js";

const jwtDecode = require("jwt-decode");

const state = {
  status: "",
  token: localStorage.getItem("songfile-token") || "",
  user: JSON.parse(localStorage.getItem("songfile-user")) || {},
  resetUser: ""
};

const getters = {
  isLoggedIn: state => !!state.token,
  authStatus: state => state.status,
  getUser: state => state.user
};

const actions = {
  login({ commit, dispatch }, user) {
    return new Promise((resolve, reject) => {
      commit("authRequest");

      axios
        .post(`${process.env.VUE_APP_API_URL}/auth/login`, user)
        .then(response => {
          const id_token = response.data.id_token;

          const decoded_two = jwtDecode(id_token);

          axios.defaults.headers.common["Authorization"] = `Token ${btoa(
            id_token
          )}`;

          axios
            .get(
              `${process.env.VUE_APP_API_URL}/users/${decoded_two["custom:songfile_user_id"]}?action=USERID`
            )
            .then(userData => {
              DataFormatter.userLogin(userData.data).then(authUser => {
                commit("authSuccess", {
                  token: btoa(id_token),
                  user: authUser
                });
                resolve(`${authUser.first_name} ${authUser.last_name}`);
              });
            });

          // DataFormatter
          //   .FAKE_USER(decoded_two["custom:songfile_user_id"])
          //   .then(authUser => {
          //     commit("authSuccess", {
          //       token: btoa(id_token),
          //       user: authUser
          //     });
          //     resolve(`${authUser.first_name} ${authUser.last_name}`);
          //   });
        })
        .catch(error => {
          commit("logout");
          commit("authError");

          if (
            error.response &&
            error.response.data &&
            error.response.data.reset
          ) {
            dispatch("accountRecovery", user.username).then(() => {
              commit("setResetUser", user.username);
              reject(error);
            });
          } else {
            reject(error);
          }
        });
    });
  },

  tokenHeader({ state, getters }) {
    if (getters.isLoggedIn) {
      axios.defaults.headers.common["Authorization"] = `Token ${state.token}`;
    }
  },

  logout({ commit }) {
    return new Promise(resolve => {
      commit("logout");
      delete axios.defaults.headers.common["Authorization"];
      resolve();
    });
  },
  updateUser({ commit }, user) {
    return new Promise((resolve, reject) => {
      const new_user = DataFormatter.userCreate(user);

      new_user.referred = "search";

      axios
        .put(`${process.env.VUE_APP_API_URL}/users/${user.user_id}`, new_user)
        .then(response => {
          commit("setUser", user);
          commit("saveUser");
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  createUser({ commit }, user) {
    return new Promise((resolve, reject) => {
      commit("authRequest");

      const new_user = DataFormatter.userCreate(user);

      axios
        .post(`${process.env.VUE_APP_API_URL}/auth/signup`, new_user)
        .then(response => {
          localStorage.setItem("userSignup", JSON.stringify(user));
          //const token = response.data.token;

          //axios.defaults.headers.common["Authorization"] = token;

          //commit("authSuccess", { token: token, user: response.data.user });
          resolve(response);
        })
        .catch(error => {
          commit("logout");
          commit("authError");
          reject(error);
        });
    });
  },
  async accountRecovery({ commit }, email) {
    commit("authRequest");

    try {
      const response = await axios.post(
        `${process.env.VUE_APP_API_URL}/auth/password/forgot`,
        { username: email }
      );
      commit("authDone");
      return { response: response, email: email };
    } catch (error) {
      commit("authError");
      throw error;
    }
  },
  accountChange({ commit }, params) {
    return new Promise((resolve, reject) => {
      commit("authRequest");

      axios
        .post(`${process.env.VUE_APP_API_URL}/auth/password/forgot/confirm`, {
          username: params.email,
          confirmation_code: params.confirm,
          password: params.password
        })
        .then(response => {
          commit("deleteResetUser");
          resolve(response);
        })
        .catch(error => {
          commit("authError");
          reject(error);
        });
    });
  }
};

const mutations = {
  authRequest(state) {
    state.status = "loading";
  },
  authDone(state) {
    state.status = "";
  },
  authSuccess(state, data) {
    state.status = "success";
    state.token = data.token;
    state.user = data.user;

    localStorage.setItem("songfile-user", JSON.stringify(state.user));
    localStorage.setItem("songfile-token", data.token);
  },
  authError(state) {
    state.status = "error";
  },
  logout(state) {
    state.status = "";
    state.token = "";
    localStorage.removeItem("songfile-token");
    localStorage.removeItem("songfile-user");
    localStorage.removeItem("songfile-releases");
  },
  setUser(state, user) {
    state.user = user;
  },
  saveUser() {
    const parsed = JSON.stringify(state.user);
    localStorage.setItem("songfile-user", parsed);
  },
  setResetUser(state, user) {
    state.resetUser = user;
  },
  deleteResetUser(state) {
    state.resetUser = "";
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
